import {Injectable} from '@angular/core';
import {BreadcrumbService} from "xng-breadcrumb";

@Injectable({
    providedIn: 'root'
})
export class BreadcrumbInitializerService {

    constructor(private breadcrumbService: BreadcrumbService) {
    }

    public setAccountName(name: string): void {
        this.breadcrumbService.set('@accountCode', name);
    }
}
