import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {SubscriptionPeriodUnit, SubscriptionPeriodUnitUtils} from 'common';
import {AdminReportGroupFilter} from '../../viewmodels/adminReportGroupFilter';
import {SubscriptionSource, SubscriptionSourceUtils} from '../../models/subscriptionSource';
import {BaseComponent} from "../base.component";
import {Arrays, SubscriptionPlanType, SubscriptionPlanTypeUtils, Title} from "common";

@Component({
    selector: 'app-admin-report-filters',
    templateUrl: './admin-report-filters.component.html',
    styleUrls: ['./admin-report-filters.component.scss']
})
export class AdminReportFiltersComponent extends BaseComponent implements OnInit {
    @Input()
    public showFilters: boolean;

    @Input()
    public showSearch: boolean;

    @Input()
    public searchLabel: string;

    @Input()
    public filter: AdminReportGroupFilter;

    @Output()
    public filterChanged: EventEmitter<AdminReportGroupFilter>;

    public subscriptionPlanType: Map<SubscriptionPlanType, Title>;
    public subscriptionPeriodUnit: Map<SubscriptionPeriodUnit, Title>;
    public subscriptionSource: Map<SubscriptionSource, Title>;

    constructor() {
        super();
        this.filterChanged = new EventEmitter<AdminReportGroupFilter>();
        this.subscriptionPlanType = new Map<SubscriptionPlanType, Title>();
        this.subscriptionPeriodUnit = new Map<SubscriptionPeriodUnit, Title>();
        this.subscriptionSource = new Map<SubscriptionSource, Title>();
    }

    ngOnInit(): void {
        for (const type of SubscriptionPlanTypeUtils.getAll()) {
            this.subscriptionPlanType.set(type, SubscriptionPlanTypeUtils.title(type));
        }

        for (const type of SubscriptionPeriodUnitUtils.getAll()) {
            this.subscriptionPeriodUnit.set(type, SubscriptionPeriodUnitUtils.title(type));
        }

        for (const type of SubscriptionSourceUtils.getAll()) {
            this.subscriptionSource.set(type, SubscriptionSourceUtils.title(type));
        }
    }

    public onQueryChange(event: string): void {
        this.filter.query = event;
        this.filterChanged.emit(this.filter);
    }

    public onSubscriptionPlanTypeClick(type: SubscriptionPlanType): void {
        if (this.filter.subscriptionPlanTypes.includes(type)) {
            Arrays.toggleValue(this.filter.subscriptionPlanTypes, type);
        } else {
            this.filter.subscriptionPlanTypes.push(type);
        }
        this.filterChanged.emit(this.filter);
    }

    public onSubscriptionSourceClick(type: SubscriptionSource): void {
        if (this.filter.subscriptionSources.includes(type)) {
            Arrays.toggleValue(this.filter.subscriptionSources, type);
        } else {
            this.filter.subscriptionSources.push(type);
        }
        this.filterChanged.emit(this.filter);
    }

    public onSubscriptionPeriodUnitClick(type: SubscriptionPeriodUnit): void {
        if (this.filter.subscriptionPeriodUnits.includes(type)) {
            Arrays.toggleValue(this.filter.subscriptionPeriodUnits, type);
        } else {
            this.filter.subscriptionPeriodUnits.push(type);
        }
        this.filterChanged.emit(this.filter);
    }
}
