<div class='container'>
    <div class='mb-3 pt-3'>
        <div class='h3'>Sensors Usage Information</div>
        <div class="flex-grow-1" style="margin-top: 24px;margin-bottom: 24px;">
            Append as many as 5 serial codes separated with commas and wait for the system to fetch their usage from the database.
            <br>Results are cached so further searches for the same serial codes are done immediately.
        </div>
        <div class="d-flex flex-row align-items-center">
            <app-text-search class="flex-grow-1"
                             [value]='searchValue'
                             [searchHint]='searchHint'
                             [maxLength]='maxLength'
                             [minLength]='minLength'
                             (submitEvent)='onSubmit()'
                             (valueChange)='onValueChanced($event)'
            >
            </app-text-search>
            <button class="m-3" color="primary" mat-raised-button type='submit'
                    [disabled]='!canSearch()'
                    (click)="onSubmit()">Search
            </button>
        </div>
    </div>
    <app-generic-admin-report-table
        [filter]='filter'
        [columnData]='columns'
        [loadingState]='loadingState'
        [dataSource]='dataSource'
        [viewModel]='viewModel'
        modelLabel='Activities'
        searchLabel='Search Sensor Usage'
        (rowClickEvent)='onRowClick($event)'
    ></app-generic-admin-report-table>
</div>
