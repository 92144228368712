export class ProtocolFindProductionLogsDataWrapper {
    position: number;
    product: string;
    application: string;
    timestampDate: number;
    message: string;
    userCode: string;
    deviceModel: string;
    productVersion: string;
    applicationVersion: string;

    constructor(position: number,
                product: string,
                application: string,
                timestampDate: number,
                message: string,
                userCode: string,
                deviceModel: string,
                productVersion: string,
                applicationVersion: string) {
        this.position = position;
        this.product = product;
        this.application = application;
        this.timestampDate = timestampDate;
        this.message = message;
        this.userCode = userCode;
        this.deviceModel = deviceModel;
        this.productVersion = productVersion;
        this.applicationVersion = applicationVersion;
    }
}
