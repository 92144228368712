import {Component, Input, OnInit} from '@angular/core';
import {SubscriptionHistoryDto} from "../../dtos/subscriptionHistory.dto";
import {AccountDto, TextUtils} from "common";
import {SubscriptionPaymentsDto} from "../../dtos/subscriptionPayments.dto";

@Component({
    selector: 'app-account-purchases-info',
    templateUrl: './account-purchases-info.component.html',
    styleUrls: ['./account-purchases-info.component.scss']
})
export class AccountPurchasesInfoComponent implements OnInit {

    @Input()
    public account: AccountDto;

    @Input()
    public purchases: SubscriptionHistoryDto[];

    constructor() {
    }

    ngOnInit(): void {
        this.purchases = this.purchases
            .sort((a, b) => a.startedOn - b.startedOn)
            .reverse();
    }

    public hasTransactionId(paymentInfo: SubscriptionPaymentsDto) {
        return !TextUtils.isEmpty(paymentInfo.transactionId);
    }

}
