import {Component, OnInit} from '@angular/core';
import {AdminNavigationService} from '../../services/admin-navigation.service';
import {BaseComponent} from '../base.component';

@Component({
    selector: 'app-home',
    templateUrl: './admin-home.component.html',
    styleUrls: ['./admin-home.component.scss']
})
export class AdminHomeComponent extends BaseComponent implements OnInit {
    public searchValue: string;

    constructor(private adminNavigation: AdminNavigationService) {
        super();
    }

    ngOnInit(): void {
        this.searchValue = '';

        this.analyticsSwitchPage('Admin', '/', '/');
    }

    onAllAccountsClick(): void {
        this.adminNavigation.goToAccounts();
    }

    onSubmit(): void {
        if (!this.searchValue || this.searchValue.length < 3) {
            this.notification.info('Enter a user email to search');
            return;
        }

        this.adminNavigation.goToAccounts(this.searchValue);
    }

    public onStatisticsDeviceUsageReportClick(): void {
        this.adminNavigation.goToReportHome();
    }

    public onStatisticsPaymentReportClick(): void {
        this.adminNavigation.goToPaymentReportHome();
    }

    public onFindLatestUsageOfSensorsClick(): void {
        this.adminNavigation.goToFindLatestUsageOfSensors();
    }

    onFindProductionLogsFromSensorClick(): void {
        this.adminNavigation.goToFindProductionLogsOfSensor();
    }

    onFindProtocolAnalysisFromSensorClick(): void {
        this.adminNavigation.goToFindProtocolAnalysisOfSensor();
    }
}
