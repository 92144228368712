import {RecentAccountActivityDto} from '../dtos/recentAccountActivity.dto';
import {SubscriptionSource, SubscriptionSourceUtils} from '../models/subscriptionSource';
import {DeviceTypes, SpecialityTypes, SubscriptionPeriodUnit, SubscriptionPeriodUnitUtils, SubscriptionPlanType, SubscriptionPlanTypeUtils, TextUtils} from 'common';

export class RecentAccountActivityReportDataViewModel {
    code: string;
    position: number;
    fullName: string;
    email: string;
    phone: string;
    specialty: string;
    createdOnDate: number;
    country: string;
    zipCode: string;
    subscription: string;
    subscriptionPeriodUnit: string;
    nextPaymentDate: number;
    lastPaymentDate: number;
    expirationDate: number;
    sessionsOneWeek: number;
    sessionsOneMonth: number;
    sessionsThreeMonths: number;
    sessionsYear: number;
    lastUsedDate: number;
    subscriptionSource: string;
    numberOfPayments: number;
    amountOfPayments: number;
    activeSubscription: boolean;

    constructor() {
        // creating a field for each device
        DeviceTypes.all().forEach(deviceType => {
                const key = deviceType.toLowerCase();
                this[key] = 0;
            }
        );
    }

    public from(reports: RecentAccountActivityDto[]): RecentAccountActivityReportDataViewModel[] {
        const data = [];
        reports.forEach((report, i) => {
            const convertedData = new RecentAccountActivityReportDataViewModel();
            convertedData.code = report.account.code;
            convertedData.position = i + 1;
            convertedData.fullName = report.account.fullName;
            convertedData.email = report.account.email;
            convertedData.phone = TextUtils.isEmpty(report.account.telephoneCountryCode) ? report.account.telephone : `${report.account.telephoneCountryCode} ${report.account.telephone}`;
            convertedData.specialty = SpecialityTypes.format(report.account.specialityType);
            convertedData.createdOnDate = report.account.createdOn;
            convertedData.country = report.account.country;
            convertedData.zipCode = report.account.zipCode;

            const latestNonExpiredSubscription = report.subscriptions
                .filter(s => !s.isExpired && s.enabled)
                .sort((a, b) => b.subscribedOn - a.subscribedOn)[0];
            convertedData.subscription = latestNonExpiredSubscription?.subscription;
            convertedData.subscriptionPeriodUnit = this.getSubscriptionPeriodUnitEmptyName(latestNonExpiredSubscription?.subscriptionPeriodUnit, latestNonExpiredSubscription?.subscriptionAuthor);
            convertedData.subscriptionSource = this.getSubscriptionSourceEmptyName(convertedData.subscription,
                latestNonExpiredSubscription?.subscriptionAuthor,
                latestNonExpiredSubscription?.subscriptionSource
            );

            const latestPayment = latestNonExpiredSubscription?.payments.sort((a, b) => b.paymentDate - a.paymentDate)[0];
            convertedData.nextPaymentDate = latestPayment?.endDate;
            convertedData.lastPaymentDate = latestPayment?.paymentDate;

            convertedData.expirationDate = latestNonExpiredSubscription && latestNonExpiredSubscription.canExpire ? latestNonExpiredSubscription.expirationDate(latestPayment) : undefined;

            convertedData.lastUsedDate = report.lastUsed;
            if (report.deviceSessions) {
                report.deviceSessions.forEach(deviceSession => {
                    const key = deviceSession.device.toLowerCase();
                    convertedData[key] = deviceSession.sessions ?? 0;
                });
            }
            convertedData.sessionsOneWeek = report.sessionsOneWeek ?? 0;
            convertedData.sessionsOneMonth = report.sessionsOneMonth ?? 0;
            convertedData.sessionsThreeMonths = report.sessionsThreeMonths ?? 0;
            convertedData.sessionsYear = report.sessionsTwelveMonths ?? 0;
            const paymentsAmount = report.subscriptions.flatMap(it => it.payments).map(payment => payment.amount);

            convertedData.numberOfPayments = paymentsAmount.length;
            convertedData.amountOfPayments = paymentsAmount.reduce((a, b) => a + b, 0);
            convertedData.activeSubscription = !!report.subscriptions.find(s => !s.isExpired && s.enabled);
            data.push(convertedData);
        });
        return data;
    }

    public getSubscriptionSourceEmptyName(subscription: string, author: string, source?: string): string {
        let name = '';
        if (!TextUtils.isEmpty(source)) {
            name = source;
        } else if (!TextUtils.isEmpty(author)) {
            name = SubscriptionSourceUtils.title(SubscriptionSource.CLOUD_ADMIN);
        } else if (subscription === SubscriptionPlanTypeUtils.title(SubscriptionPlanType.PRO) ||
            subscription === SubscriptionPlanTypeUtils.title(SubscriptionPlanType.PRO_PLUS) ||
            subscription === SubscriptionPlanTypeUtils.title(SubscriptionPlanType.STARTER) ||
            subscription === SubscriptionPlanTypeUtils.title(SubscriptionPlanType.PREMIUM) ||
            subscription === SubscriptionPlanTypeUtils.title(SubscriptionPlanType.EXCELLENCE)) {
            name = SubscriptionSourceUtils.title(SubscriptionSource.ESHOP);
        }
        return name;
    }

    public getSubscriptionPeriodUnitEmptyName(subscriptionPeriodUnit: string, author: string): string {
        let name = '';
        if (!TextUtils.isEmpty(subscriptionPeriodUnit)) {
            name = subscriptionPeriodUnit;
        } else if (!TextUtils.isEmpty(author)) {
            name = SubscriptionPeriodUnitUtils.title(SubscriptionPeriodUnit.CUSTOM);
        }
        return name;
    }
}
