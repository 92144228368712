<h1 class="flex-grow-1">Account Information</h1>
<mat-list>
    <mat-list-item class="gender" title="Gender" *ngIf="accountIsPatient(account.accountRoleType)">
        <mat-icon mat-list-icon>face</mat-icon>
        <span>{{account.gender}}</span>
    </mat-list-item>
    <mat-list-item class="created-updated" title="Created and Updated">
        <mat-icon mat-list-icon>schedule</mat-icon>
        <div mat-line class="detail">
            <small>Created on</small>
            <div>{{account.createdOn | date: 'medium'}}</div>
        </div>
    </mat-list-item>
    <mat-list-item title="Specialty">
        <mat-icon mat-list-icon>domain</mat-icon>
        <div mat-line class="detail">
            <small>Specialty</small>
            <div class="text-capitalize">{{formatSpecialty()}}</div>
        </div>
    </mat-list-item>
    <mat-list-item title="Country">
        <mat-icon mat-list-icon>public</mat-icon>
        <div mat-line class="detail">
            <small>Country</small>
            <div class="text-capitalize">{{formatCountryName()}}</div>
        </div>
    </mat-list-item>
    <mat-list-item title="Role">
        <mat-icon mat-list-icon>local_police</mat-icon>
        <div mat-line class="detail">
            <small>Role</small>
            <div class="text-capitalize">
                {{account.accountRole.toLowerCase()}}
            </div>
        </div>
    </mat-list-item>
    <mat-list-item title="Deleted">
        <mat-icon mat-list-icon>remove_circle</mat-icon>
        <div mat-line class="detail">
            <small>Deleted</small>
            <div>{{account.deleted ? 'Yes' : 'No'}}</div>
        </div>
    </mat-list-item>
    <mat-list-item title="Terms of Service. Version and Consent on">
        <mat-icon mat-list-icon>history_edu</mat-icon>
        <div mat-line class="detail">
            <small>Terms Version: {{account.termsOfUseVersion}}</small>
            <div>{{account.termsOfUseConsentOn | date:'medium'}}</div>
        </div>
    </mat-list-item>
    <mat-list-item title="Sales Person" class="mt-1">
        <mat-icon mat-list-icon>storefront</mat-icon>
        <div mat-line class="detail">
            <small>Salesman</small>
            <div class="outer d-flex">
                <input matInput type="text"
                       class="w-50 border"
                       style="margin-right: 0.3em"
                       [(ngModel)]="salesman"
                        placeholder="{{salesmanPlaceholder}}"
                        [maxlength]=250
                >
                <button color="primary" mat-raised-button type="submit" [disabled]="!saveSalesmanVisible" (click)="saveSalesman()">
                    Save
                </button>
            </div>
        </div>
    </mat-list-item>
</mat-list>
