import {Injectable} from '@angular/core';
import {Router} from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class AdminNavigationService {

    constructor(private router: Router) {
    }

    public goToHome(): Promise<boolean> {
        return this.router.navigate(['/']);
    }

    public goToLogin(): Promise<boolean> {
        return this.router.navigate(['/auth/login']);
    }

    public goToAdminHome(): Promise<boolean> {
        return this.router.navigate(['/']);
    }

    public goToAccounts(query?: string): Promise<boolean> {
        if (query) {
            return this.router.navigate(['/accounts'], {queryParams: {query}});
        } else {
            return this.router.navigate(['/accounts']);
        }
    }

    public goToAccount(accountCode: string, ): Promise<boolean> {
        return this.router.navigate(['/accounts', accountCode]);
    }

    public goToAccountAddSubscription(accountCode: string, ): Promise<boolean> {
        return this.router.navigate([`/accounts/${accountCode}/subscriptions/add`]);
    }

    public goToFindLatestUsageOfSensors(sensors?: string, ): Promise<boolean> {
        if (sensors) {
            return this.router.navigate(['/findSensorLatestUsage'],
                {queryParams: {sensors}});
        } else {
            return this.router.navigate(['/findSensorLatestUsage']);
        }
    }

    public goToFindProductionLogsOfSensor(sensor?: string, startDate?: number, endDate?: number): Promise<boolean> {
        return this.router.navigate(['/findSensorProductionLogs'],
            {queryParams: {sensor, startDate, endDate}});
    }

    public goToFindProtocolAnalysisOfSensor(sensor?: string): Promise<boolean> {
        return this.router.navigate(['/findSensorProtocolAnalysis'],
            {queryParams: {sensor}});
    }

    public goToReportHome(): Promise<boolean> {
        return this.router.navigate(['/recentAccountActivityReport']);
    }

    public goToPaymentReportHome(): Promise<boolean> {
        return this.router.navigate(['/recentAccountPaymentsReport']);
    }

    public goToDevPlayground(): Promise<boolean> {
        return this.router.navigate(['/dev']);
    }
}
