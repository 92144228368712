import {DeviceType, Dto} from "common";

export class DeviceSessions extends Dto {
    device: DeviceType;
    sessions: number;

    deserialize(input: any): this {
        Object.assign(this, input);
        return this;
    }
}
