<div class="d-flex flex-row mb-3">
    <h1 class="flex-grow-1">Subscriptions</h1>
    <button color="primary" mat-raised-button (click)="onAddSubscriptionPress.emit()">
        <mat-icon>add_circle_outline</mat-icon>
        Add Subscription
    </button>
</div>

<div class="d-flex align-items-center mb-2">
    <mat-icon class="icon-active me-1">power_settings_new</mat-icon>
    Active
</div>
<app-subscriptions-panel [subscriptions]="getActiveSubscriptions()" (onDeactivate)="onDeactivateSubscriptionPress.emit($event)"></app-subscriptions-panel>

<div class="d-flex align-items-center mt-4 mb-2">
    <mat-icon class="icon-inactive me-1">power_settings_new</mat-icon>
    Inactive
</div>
<app-subscriptions-panel [subscriptions]="getInactiveSubscriptions()"></app-subscriptions-panel>
