import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {MatDatepickerInputEvent} from '@angular/material/datepicker';
import {takeUntil} from 'rxjs/operators';
import {MatSnackBar} from '@angular/material/snack-bar';
import {SubscriptionEnrollDto} from '../../dtos/subscriptionEnroll.dto';
import {AdminNavigationService} from "../../services/admin-navigation.service";
import {SubscriptionAdminService} from "../../services/api/subscription-admin.service";
import {BaseComponent} from "../base.component";
import {SubscriptionPlan, SubscriptionPlanDaoService, SubscriptionPlanType} from "common";

@Component({
    selector: 'app-subscription-form',
    templateUrl: './add-subscription.component.html',
    styleUrls: ['./add-subscription.component.scss'],
})

export class AddSubscriptionComponent extends BaseComponent implements OnInit {
    private _userCode: string;
    public plans: SubscriptionPlan[];
    public legacyPlans: SubscriptionPlan[];
    public selectedPlan: SubscriptionPlan;
    private _selectedDuration: number;
    private _showCalendarComponent: boolean = false;

    constructor(private _route: ActivatedRoute,
                private _subscriptionAdminService: SubscriptionAdminService,
                private _snackbar: MatSnackBar,
                private subscriptionPlanDaoService: SubscriptionPlanDaoService,
                private adminNavigation: AdminNavigationService) {
        super();
        this.plans = [subscriptionPlanDaoService.getForType(SubscriptionPlanType.STARTER), subscriptionPlanDaoService.getForType(SubscriptionPlanType.PREMIUM), subscriptionPlanDaoService.getForType(SubscriptionPlanType.EXCELLENCE)];
        this.legacyPlans = [subscriptionPlanDaoService.getForType(SubscriptionPlanType.PRO), subscriptionPlanDaoService.getForType(SubscriptionPlanType.PRO_PLUS)];
    }

    ngOnInit(): void {
        this._route.paramMap
            .pipe(takeUntil(this.destroySubject))
            .subscribe((params) => {
                this._userCode = params.get('code');
            });
    }

    onPlanSelected(plan: SubscriptionPlan) {
        this.selectedPlan = plan;
    }

    onIndefiniteSelected() {
        this._selectedDuration = -1;
        this._showCalendarComponent = false;
    }

    onDurationSelected() {
        this._selectedDuration = undefined;
        this._showCalendarComponent = true;
    }

    onDateChanged(event: MatDatepickerInputEvent<Date>) {
        if (!event.value) {
            return;
        }
        const endingDate = new Date(event.value.toString()).getTime();
        const nowDate = new Date().getTime();
        const diffTime = Math.abs(endingDate - nowDate);
        this._selectedDuration = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    }

    onAddSubscription() {
        if (this._userCode && this.selectedPlan && this._selectedDuration) {
            this._subscriptionAdminService.enrollSubscription(new SubscriptionEnrollDto(this._userCode, this.selectedPlan.code, this._selectedDuration))
                .subscribe(_response => {
                    this.adminNavigation.goToAccount(this._userCode);
                }, error => {
                    this.notification.info(`Add Subscription Failed: ${error.statusText} (${error.status})`);
                });
        }
    }

    public validDate(): boolean {
        return !!this._selectedDuration;
    }

    public get selectedPlanType(): SubscriptionPlanType | null {
        if (!this.selectedPlan) {
            return null;
        }
        return this.selectedPlan.type;
    }

    public get showCalendarComponent(): boolean {
        return this._showCalendarComponent;
    }
}
