import {Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {interval, Subject} from 'rxjs';
import {startWith, takeUntil} from 'rxjs/operators';
import {ReportAdminService} from '../../services/api/report-admin.service';
import {AdminNavigationService} from '../../services/admin-navigation.service';
import {AdminReportGroupFilter} from '../../viewmodels/adminReportGroupFilter';
import {AccountAdminDto} from '../../dtos/accountAdmin.dto';
import {AccountRole, DateFormat, Dates, LoadingState, Title} from 'common';
import {BaseComponent} from '../base.component';
import {RecentAccountPaymentsReportDataViewModel} from '../../viewmodels/recentAccountPaymentsReportDataViewModel';

@Component({
    selector: 'app-report',
    templateUrl: './recent-account-payments-report.component.html',
    styleUrls: ['./recent-account-payments-report.component.scss']
})

export class RecentAccountPaymentsReportComponent extends BaseComponent implements OnInit {
    public readonly dataSource: MatTableDataSource<RecentAccountPaymentsReportDataViewModel>;

    public columns: Map<string, Title>;
    public loadingState: LoadingState;
    public loadingProgress: number;
    public loadingStatus: string;
    public dateCreated: string;
    public fileName: string;
    public viewModel: RecentAccountPaymentsReportDataViewModel;
    private reportFinished: Subject<void>;
    public filter: AdminReportGroupFilter;

    @ViewChild(MatSort)
    public sort: MatSort;

    constructor(private route: ActivatedRoute, private reportAdminService: ReportAdminService, private adminNavigation: AdminNavigationService) {
        super();
        this.dataSource = new MatTableDataSource([]);

        this.columns = new Map<string, string>();
        this.columns.set('position', 'No');
        this.columns.set('fullName', 'Name');
        this.columns.set('email', 'Mail');
        this.columns.set('subscription', 'Subscription');
        this.columns.set('subscriptionPeriodUnit', 'subscription Period');
        this.columns.set('subscriptionSource', 'Subscription Source');
        this.columns.set('endDate', 'Next Payment');
        this.columns.set('paymentDate', 'Last Payment');
        this.columns.set('expirationDate', 'Expiration Date');
        this.columns.set('amount', 'Amount');
        this.columns.set('discountPercentage', 'Discount %');
        this.columns.set('renewalStatus', 'active');

        this.viewModel = new RecentAccountPaymentsReportDataViewModel();
        this.reportFinished = new Subject<void>();

        this.filter = new AdminReportGroupFilter();
        this.filter.supportsQuery = true;
        this.filter.supportsSubscriptionPlanTypes = true;
        this.filter.supportsSubscriptionPeriodUnits = true;
        this.filter.supportsSubscriptionSources = true;
    }

    ngOnInit(): void {
        this.analyticsSwitchPage('Admin-RecentAccountPaymentsReport', '', '/admin/recentAccountPaymentsReport');

        // Initiate report generation and periodically check status
        this.loadingState = LoadingState.LOADING;

        const pollingIntervalMs = 2500;
        interval(pollingIntervalMs).pipe(
            startWith(0), // start immediately
            takeUntil(this.reportFinished),
            takeUntil(this.destroySubject),
        ).subscribe(_avoid => this.checkReportStatus());
    }

    private checkReportStatus(): void {
        this.reportAdminService.getRecentAccountPayments()
            .pipe(takeUntil(this.destroySubject))
            .subscribe(report => {
                const reportFinished = report.reportStatus.dateCreated !== 0;
                if (!reportFinished) {
                    this.loadingProgress = report.reportStatus.progress;
                    this.loadingStatus = report.reportStatus.progress.toString();
                    return;
                }
                this.reportFinished.next();
                this.reportFinished.complete();
                const reportUserAccounts = report.subscriptionPayments.filter(datum => datum.account.accountRole !== AccountRole.PATIENT);

                // display results
                this.dataSource.data = this.viewModel.from(reportUserAccounts);
                const date = new Date(report.reportStatus.dateCreated);
                this.dateCreated = Dates.format(date, DateFormat.DATE_TIME_LONG);
                this.fileName = `account_activity_report_${Dates.format(date, DateFormat.DATE_TIME_CONDENSED)}`;

                this.loadingState = LoadingState.LOADED;
            }, (error) => {
                this.errorHandler.handle(`Could not create report. ${error.statusText} (${error.status}`);
                this.notification.info(`Could not create report. ${error.statusText} (${error.status}`);
                this.loadingState = LoadingState.ERROR;
            });
    }

    onRowClick(row: AccountAdminDto): void {
        this.adminNavigation.goToAccount(row.code);
    }
}
