/**
 * Filter to be applied to admin report
 */
import {SubscriptionSource} from '../models/subscriptionSource';
import {Dto, SubscriptionPeriodUnit, SubscriptionPlanType} from "common";

export class AdminReportGroupFilter extends Dto {
    public query: string;
    public subscriptionPlanTypes: SubscriptionPlanType[];
    public subscriptionPeriodUnits: SubscriptionPeriodUnit[];
    public subscriptionSources: SubscriptionSource[];
    public supportsQuery: boolean;
    public supportsSubscriptionPlanTypes: boolean;
    public supportsSubscriptionPeriodUnits: boolean;
    public supportsSubscriptionSources: boolean;

    constructor() {
        super();
        this.query = '';
        this.subscriptionPlanTypes = [];
        this.subscriptionPeriodUnits = [];
        this.subscriptionSources = [];
    }

    public deserialize(input: any): this {
        Object.assign(this, input);
        return this;
    }
}
