import {BaseDto} from "common";
import {SubscriptionPaymentsDto} from "./subscriptionPayments.dto";

export class SubscriptionHistoryDto extends BaseDto {
    startedOn: number;
    product: string;
    billingPeriod: number;
    status: string;
    currency: string;
    price: string;
    payments: Array<SubscriptionPaymentsDto>;

    deserialize(input: any): this {
        Object.assign(this, input);
        if (input.payments) {
            this.payments = this.deserializeArray(input.payments, SubscriptionPaymentsDto);
        }
        return this;
    }

}
