export class ProtocolFindBySensorDataWrapper {
    position: number;
    serialCode: string;
    notes: string;
    userCode: string;
    lastTimeUsedDate: number;
    firstTimeUsedDate: number;
    searchedAtDate: number;

    constructor(position: number,
                serialCode: string,
                notes: string,
                userCode: string,
                lastTimeUsedDate: number,
                firstTimeUsedDate: number,
                searchedAtDate: number) {
        this.position = position;
        this.serialCode = serialCode;
        this.notes = notes;
        this.userCode = userCode;
        this.lastTimeUsedDate = lastTimeUsedDate;
        this.firstTimeUsedDate = firstTimeUsedDate;
        this.searchedAtDate = searchedAtDate;
    }
}
