import {Component} from '@angular/core';
import {BaseComponent} from "../base.component";
import {Title} from "common";
import {environment} from "../../../environments/environment";

@Component({
    selector: 'app-toolbar',
    templateUrl: './toolbar.component.html',
    styleUrls: ['./toolbar.component.scss']
})
export class ToolbarComponent extends BaseComponent {
    public title: Title;

    public toolbarVisible: boolean;

    constructor() {
        super();
        this.title = '⚡ Kinvent Admin ⚡';
    }

    public ngOnInit(): void {
        this.toolbarVisible = this.userAccount !== undefined;
    }

    public onTitlePress() {
        this.navigation.goToHome();
    }

    public isDev(): boolean {
        return !environment.production;
    }

    public onDevClick() {
        this.navigation.goToDevPlayground();
    }

    public get fullName() {
        return this.authenticationService.account.firstName;
    }

    public onProfileSelected() {
        this.notification.info('Not implemented');
    }

    public onLogoutSelected() {
        this.authenticationService.logout();
    }
}
