import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute} from '@angular/router';
import {AccountDto, AccountRole, LoadingState} from "common";
import {BaseComponent} from "../base.component";

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent extends BaseComponent implements OnInit {
    private _loginForm: FormGroup;
    public loadingState: LoadingState;

    constructor(private _fb: FormBuilder, private _route: ActivatedRoute) {
        super();
    }

    ngOnInit(): void {
        // if user is logged in take them home
        if (this.userAccount !== null) {
            this.navigation.goToHome();
        }

        this.analyticsSwitchPage('Login', undefined, '/login');

        this._loginForm = this._fb.group({
            email: ['', [Validators.required, Validators.email]],
            password: ['', [Validators.required, Validators.minLength(6)]]
        });
        this.loadingState = LoadingState.LOADED;
    }

    get email() {
        return this._loginForm.get('email');
    }

    get password() {
        return this._loginForm.get('password');
    }

    get loginForm() {
        return this._loginForm;
    }

    onFormSubmit() {
        this.loadingState = LoadingState.LOADING;
        this.authenticationService.login(this.email.value, this.password.value)
            .subscribe(
                account => {
                    this.loadingState = LoadingState.LOADED;
                    switch (account.accountRoleType) {
                        case AccountRole.ADMIN:
                        case AccountRole.MANAGER:
                            console.log('Login successful.');
                            this.navigation.goToAdminHome();
                            break;
                        default:
                            this.notification.info('Unauthorized');
                            this.authenticationService.logout();
                            break;
                    }
                },
                error => {
                    this.loadingState = LoadingState.LOADED;
                    this.notification.info(`Login Failed: ${error.statusText} (${error.status})`);
                });
    }

    /**
     * Returns the currently logged in account
     */
    public get userAccount(): AccountDto {
        return this.authenticationService.account;
    }
}
