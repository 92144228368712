import {BaseDto} from "common";
import {SubscriptionPaymentDto} from "./subscriptionPayment.dto";
import {SubscriptionSource} from "../models/subscriptionSource";

/**
 * reflects RecentAccountActivitySubscriptionDto dto on server
 */
export class RecentAccountActivitySubscriptionDto extends BaseDto {
    private static readonly DAY_IN_MS = 1000 * 60 * 60 * 24;

    eshopOrderId: string;
    appStoreReceipt: string;
    subscription: string;
    subscriptionSource: string;
    subscriptionAuthor: string;
    subscribedOn: number;
    renewalStatus: boolean;
    duration: number;
    enabled: boolean;
    subscriptionPeriodUnit: string;
    payments: SubscriptionPaymentDto[];
    notes: string;

    public deserialize(input: any): this {
        Object.assign(this, input);

        this.payments = this.deserializeArray(input.payments, SubscriptionPaymentDto);

        return this;
    }

    public get isExpired(): boolean {
        return this.duration >= 0 && this.subscribedOn + RecentAccountActivitySubscriptionDto.DAY_IN_MS * this.duration! < new Date().getTime();
    }

    public get canExpire(): boolean {
        return this.duration >= 0;
    }

    public expirationDate(latestPayment: SubscriptionPaymentDto): number {
            let expiration;

            if (this.subscriptionSource === SubscriptionSource.ESHOP) {
                expiration = latestPayment.endDate;
            } else {
                let milliseconds = this.duration ? this.duration * RecentAccountActivitySubscriptionDto.DAY_IN_MS : 0;
                expiration =  new Date(this.subscribedOn ? this.subscribedOn + milliseconds : milliseconds);
            }

            return new Date(expiration).getTime();
    }
}
