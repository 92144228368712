import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
    selector: 'app-serial-code',
    templateUrl: 'dialog-show-detailed-message.html',
    styleUrls: ['dialog-show-detailed-message.scss']
})
export class DialogShowDetailedMessageComponent {
    constructor(@Inject(MAT_DIALOG_DATA) public data: {message: string, title: string }) {}
}
