import {Component, OnInit, ViewChild} from '@angular/core';
import {AccountsController} from '../../services/AccountsController';
import {ActivatedRoute} from '@angular/router';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {Observable} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {AdminNavigationService} from "../../services/admin-navigation.service";
import {AdminReportGroupFilter} from '../../viewmodels/adminReportGroupFilter';
import {AccountTableDataViewModel} from "../../viewmodels/accountTableDataViewModel";
import {AccountAdminDto} from "../../dtos/accountAdmin.dto";
import {BaseComponent} from "../base.component";
import {LoadingState, NotificationService, Title} from "common";

@Component({
    selector: 'app-accounts',
    templateUrl: './accounts.component.html',
    styleUrls: ['./accounts.component.scss']
})
export class AccountsComponent extends BaseComponent implements OnInit {
    private query?: any;
    public showSearch: boolean;
    public readonly dataSource: MatTableDataSource<AccountTableDataViewModel>;
    public columns: Map<string, Title>;
    public loadingState: LoadingState;
    public viewModel: AccountTableDataViewModel;
    public filter: AdminReportGroupFilter;

    @ViewChild(MatSort)
    public sort: MatSort;

    constructor(private accountsController: AccountsController, private notificationService: NotificationService, private route: ActivatedRoute,
                private adminNavigation: AdminNavigationService) {
        super();
        this.loadingState = LoadingState.LOADING;
        this.dataSource = new MatTableDataSource([]);

        this.viewModel = new AccountTableDataViewModel();
        this.columns = new Map<string, string>();
        this.columns.set('position', 'No');
        this.columns.set('fullName', 'Full name');
        this.columns.set('email', 'Email');
        this.columns.set('createdOnDate', 'Created on');
        this.columns.set('countryFull', 'Country');

        this.filter = new AdminReportGroupFilter();
        this.filter.supportsQuery = true;
        this.filter.supportsSubscriptionPlanTypes = false;
        this.filter.supportsSubscriptionPeriodUnits = false;
        this.filter.supportsSubscriptionSources = false;
    }

    ngOnInit(): void {
        this.analyticsSwitchPage('Admin-Accounts', undefined, '/admin/accounts');

        this.route.queryParams
            .pipe(takeUntil(this.destroySubject))
            .subscribe(params => {
                    this.query = params;
                    this.loadingState = LoadingState.LOADING;
                    let accountsObservable: Observable<AccountAdminDto[]>;
                    if (this.query.query) {
                        this.showSearch = false;
                        accountsObservable = this.accountsController.getByEmailLikeQuery(this.query.query);
                    } else {
                        this.showSearch = true;
                        accountsObservable = this.accountsController.getAll();
                    }

                    accountsObservable.subscribe(accounts => {
                        this.dataSource.data = AccountTableDataViewModel.from(accounts);

                        this.loadingState = LoadingState.LOADED;
                    }, error => {
                        this.loadingState = LoadingState.ERROR;
                        this.notificationService.info(`Cannot get accounts: ${error.statusText} (${error.status})`);
                    });
                }
            );
    }

    public onRowClick(row: AccountAdminDto): void {
        this.adminNavigation.goToAccount(row.code)
            .then(_avoid => Promise.resolve())
            .catch(err => this.notification.info(`go to account Failed: ${err.statusText} (${err.status})`));
    }
}
