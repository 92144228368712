<div class="component">
    <div *ngIf="filter.supportsQuery">
        <app-text-search
            [searchHint]='searchLabel'
            (valueChange)="onQueryChange($event)"
        ></app-text-search>
    </div>
    <div *ngIf="filter.supportsSubscriptionPlanTypes">
        <div class="filter mt-2 pe-3">
            <div class="title mb-1">Subscription Plan Type</div>
            <mat-chip-list multiple="true">
                <mat-chip *ngFor="let type of subscriptionPlanType | keyvalue"
                          [selected]="filter.subscriptionPlanTypes.includes(type.key)"
                          class="tag tag-{{type.key.toString().toLowerCase()}}"
                          (click)="onSubscriptionPlanTypeClick(type.key)">
                    {{type.value}}
                </mat-chip>
            </mat-chip-list>
        </div>
    </div>
    <div *ngIf="filter.supportsSubscriptionPeriodUnits">
        <div class="filter mt-2 pe-3">
            <div class="title mb-1">Subscription Period</div>
            <mat-chip-list multiple="true">
                <mat-chip *ngFor="let type of subscriptionPeriodUnit | keyvalue"
                          [selected]="filter.subscriptionPeriodUnits.includes(type.key)"
                          class="tag tag-{{type.key.toString().toLowerCase()}}"
                          (click)="onSubscriptionPeriodUnitClick(type.key)">
                    {{type.value}}
                </mat-chip>
            </mat-chip-list>
        </div>
    </div>
    <div *ngIf="filter.supportsSubscriptionSources">
        <div class="filter mt-2">
            <div class="title mb-1">Subscription Source</div>
            <mat-chip-list multiple="true">
                <mat-chip *ngFor="let type of subscriptionSource | keyvalue"
                          [selected]="filter.subscriptionSources.includes(type.key)"
                          class="tag tag-{{type.key.toString().toLowerCase()}}"
                          (click)="onSubscriptionSourceClick(type.key)">
                    {{type.value}}
                </mat-chip>
            </mat-chip-list>
        </div>
    </div>
</div>
