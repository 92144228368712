import {BaseDto} from "common";

/**
 * reflects SubscriptionPaymentDto dto on server
 */
export class SubscriptionPaymentDto extends BaseDto {
    amount: number;
    paymentDate: number;
    endDate: number;
    discountPercentage: number;

    public deserialize(input: any): this {
        Object.assign(this, input);
        return this;
    }
}
