import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {SubscriptionDto} from 'common';
import {map} from 'rxjs/operators';
import {environment} from "../../../environments/environment";
import {Router} from '@angular/router';
import {SubscriptionEnrollDto} from '../../dtos/subscriptionEnroll.dto';
import {SubscriptionHistoryDto} from '../../dtos/subscriptionHistory.dto';

@Injectable({
    providedIn: 'root'
})
export class SubscriptionAdminService {

    constructor(private _httpClient: HttpClient, private _router: Router) {
    }

    public getForAccount(accountCode: string): Observable<SubscriptionDto[]> {
        const httpParams = new HttpParams().set('accountCode', accountCode);
        return this._httpClient.get<any>(environment.apiUrl + 'api/subscriptions/admin/v1/findByAccountCode/', {params: httpParams})
            .pipe(map(subscriptionData => {
                const subscriptions: SubscriptionDto[] = [];
                for (const datum of (subscriptionData as any)) {
                    subscriptions.push(new SubscriptionDto().deserialize(datum));
                }
                return subscriptions;
            }));
    }

    public getPurchasesHistory(accountCode: string): Observable<SubscriptionHistoryDto[]> {
        const httpParams = new HttpParams().set('accountCode', accountCode);
        return this._httpClient.get<any>(environment.apiUrl + 'api/integration/subscription/v1/subscriptionHistory', {params: httpParams})
            .pipe(map(purchasesHistoryData => {
                const purchasesHistory: SubscriptionHistoryDto[] = [];
                for (const datum of (purchasesHistoryData as any)) {
                    purchasesHistory.push(new SubscriptionHistoryDto().deserialize(datum));
                }
                return purchasesHistory;
            }));
    }

    public enrollSubscription(enrollDto: SubscriptionEnrollDto): Observable<void> {
        return this._httpClient.post<any>(environment.apiUrl + 'api/subscriptions/admin/v1/enroll', enrollDto);
    }

    public deactivateSubscription(code: string): Observable<void> {
        return this._httpClient.delete<any>(environment.apiUrl + 'api/subscriptions/admin/v1/' + code);
    }
}
