<div class="container mt-4">
    <h1 class="mb-3">Add subscription Plan</h1>
    <div class="section">
        <h2>1. Select Plan</h2>
        <mat-list class="plans row ms-5">
            <mat-card *ngFor="let plan of plans" class="plan col-2 d-inline-block ms-2 me-2" (click)="onPlanSelected(plan)"
                      [ngClass]="{'selected': selectedPlanType === plan.type}">
                <span class="selection">
                    <mat-icon>check_circle</mat-icon>
                </span>
                <mat-card-content>
                    <mat-card-title>{{plan.name}}</mat-card-title>
                </mat-card-content>
            </mat-card>
        </mat-list>
        <mat-list class="plans row ms-5">
            <mat-card *ngFor="let plan of legacyPlans" class="plan col-2 d-inline-block ms-2 me-2" (click)="onPlanSelected(plan)"
                      [ngClass]="{'selected': selectedPlanType === plan.type}">
                <span class="selection">
                    <mat-icon>check_circle</mat-icon>
                </span>
                <mat-card-content>
                    <mat-card-title>{{plan.name}}</mat-card-title>
                </mat-card-content>
            </mat-card>
        </mat-list>
    </div>
    <div class="section">
        <h2>2. Select Expiration</h2>
        <mat-card class="ms-5 me-5">
            <mat-list class="duration">
                <mat-radio-button color="primary" (click)="onIndefiniteSelected()">Indefinite</mat-radio-button>
                <mat-radio-button color="primary" (click)="onDurationSelected()" class="right-button"> Expires On</mat-radio-button>
                <mat-form-field appearance="fill" *ngIf="showCalendarComponent" class="picker">
                    <mat-label>(mm/dd/yyyy)</mat-label>
                    <input matInput [matDatepicker]="picker" (dateChange)="onDateChanged($event)">
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                </mat-form-field>
            </mat-list>
        </mat-card>
    </div>
    <div class="action-button">
        <button color="primary" mat-raised-button (click)="onAddSubscription()" [disabled]="!(selectedPlan && validDate())">
            Add Subscription
        </button>
    </div>
</div>
