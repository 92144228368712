import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {LoginComponent} from './components/login/login.component';
import {AdminHomeComponent} from './components/admin-home/admin-home.component';
import {AccountsComponent} from './components/accounts/accounts.component';
import {AccountComponent} from './components/account/account.component';
import {AddSubscriptionComponent} from './components/add-subscription/add-subscription.component';
import {RecentAccountActivityReportComponent} from './components/recent-account-activity-report/recent-account-activity-report.component';
import {AuthGuard} from './guards/auth-guard';
import {DevGuard} from './guards/dev-guard.service';
import {DevPlaygroundComponent} from './dev-playground/dev-playground.component';
import {
    RecentAccountPaymentsReportComponent
} from './components/recent-account-payments-report/recent-account-payments-report.component';
import {FindSensorLatestUsageComponent} from './components/find-sensor-latest-usage/find-sensor-latest-usage.component';
import {FindSensorProductionLogsComponent} from './components/find-sensor-production-logs/find-sensor-production-logs.component';
import {FindSensorProtocolAnalysisComponent} from './components/find-sensor-protocol-analysis/find-sensor-protocol-analysis.component';

const routes: Routes = [
    // unauthorized
    {path: 'auth/login', component: LoginComponent, data: {breadcrumb: {skip: true}}},

    // Role Admin
    {
        path: '',
        data: {breadcrumb: 'Administrator'},
        canActivate: [AuthGuard],
        children: [
            {path: '', component: AdminHomeComponent},
            {
                path: 'accounts',
                data: {breadcrumb: 'Accounts'},
                children: [
                    {path: '', component: AccountsComponent},
                    {
                        path: ':code',
                        data: {
                            breadcrumb: {label: 'Account', alias: 'accountCode'}
                        },
                        children: [
                            {path: '', component: AccountComponent},
                            {path: 'subscriptions/add', component: AddSubscriptionComponent, data: {breadcrumb: {skip: true}}}
                        ]
                    }
                ]
            },
            {path: 'recentAccountActivityReport', component: RecentAccountActivityReportComponent, data: {breadcrumb: 'Report - Account Activity'}},
            {path: 'recentAccountPaymentsReport', component: RecentAccountPaymentsReportComponent, data: {breadcrumb: 'Report - Account Payments'}},
            {path: 'findSensorLatestUsage', component: FindSensorLatestUsageComponent, data: {breadcrumb: 'Find Sensor Latest Usage'}},
            {path: 'findSensorProductionLogs', component: FindSensorProductionLogsComponent, data: {breadcrumb: 'Find Sensor Production Logs'}},
            {path: 'findSensorProtocolAnalysis', component: FindSensorProtocolAnalysisComponent, data: {breadcrumb: 'Find Sensor Protocol Analysis'}},
        ]
    },

    // Dev
    {
        path: 'dev', component: DevPlaygroundComponent, canActivate: [AuthGuard, DevGuard], data: {breadcrumb: {skip: true}}
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {relativeLinkResolution: 'corrected'})],
    exports: [RouterModule]
})
export class AppRoutingModule {

}
