import {DeviceTypes} from "common";
import {SubscriptionPaymentsHistoryDto} from "../dtos/subscriptionPaymentsHistory.dto";
import { SubscriptionSource, SubscriptionSourceUtils } from "../models/subscriptionSource";

export class RecentAccountPaymentsReportDataViewModel {
    code: string;
    position: number;
    fullName: string;
    email: string;
    subscription: string;
    subscriptionPeriodUnit: string;
    expirationDate: number;
    endDate: number;
    paymentDate: number;
    subscriptionSource: string;
    amount: number;
    discountPercentage: number;
    renewalStatus: boolean;

    constructor() {
        // creating a field for each device
        DeviceTypes.all().forEach(deviceType => {
                const key = deviceType.toLowerCase();
                this[key] = 0;
            }
        );
    }

    public from(reports: SubscriptionPaymentsHistoryDto[]): RecentAccountPaymentsReportDataViewModel[] {
        const data = [];
        let count = 0;
        reports.forEach((report) => {
            report.subscriptions.forEach((subscription) => {
                subscription.payments.forEach((payment) => {
                    count++;
                    const convertedData = new RecentAccountPaymentsReportDataViewModel();
                    convertedData.code = report.account.code;
                    convertedData.position = count;
                    convertedData.fullName = report.account.fullName;
                    convertedData.email = report.account.email;
                    convertedData.subscription = subscription.subscription;
                    convertedData.subscriptionPeriodUnit = subscription.subscriptionPeriodUnit;
                    convertedData.subscriptionSource = subscription.subscriptionSource ? SubscriptionSourceUtils.title(subscription.subscriptionSource as SubscriptionSource) : undefined;
                    convertedData.expirationDate =  subscription.canExpire ? subscription.expirationDate(payment) : undefined;
                    convertedData.amount = payment.amount;
                    convertedData.paymentDate = payment.paymentDate;
                    convertedData.endDate = payment.endDate;
                    convertedData.discountPercentage = Math.round(payment.discountPercentage);
                    convertedData.renewalStatus = subscription.renewalStatus;
                    data.push(convertedData);
                });
            })

        });

        return data;
    }
}
