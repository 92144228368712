import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'app-dev-playground',
    templateUrl: './dev-playground.component.html',
    styleUrls: ['./dev-playground.component.scss']
})
export class DevPlaygroundComponent implements OnInit {

    constructor() {
    }

    ngOnInit(): void {
    }

}
