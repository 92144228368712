import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {map} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {RecentAccountActivityReportDto} from "../../dtos/recentAccountActivityReport.dto";
import {environment} from "../../../environments/environment";
import {recentAccountPaymentHistoryReportDto} from "../../dtos/recentAccountPaymentHistoryReport.dto";

@Injectable({
    providedIn: 'root'
})
export class ReportAdminService {

    constructor(private httpClient: HttpClient) {
    }

    public getRecentAccountActivity(): Observable<RecentAccountActivityReportDto> {
        return this.httpClient.get<any>(environment.apiUrl + 'api/reports/v1/recentAccountActivityReport')
            .pipe(map((reportsData: any) => {
                return new RecentAccountActivityReportDto().deserialize(reportsData);
            }));
    }


    public getRecentAccountPayments(): Observable<recentAccountPaymentHistoryReportDto> {
        return this.httpClient.get<any>(environment.apiUrl + 'api/reports/v1/recentAccountPaymentHistory')
            .pipe(map((reportsData: any) => {
                return new recentAccountPaymentHistoryReportDto().deserialize(reportsData);
            }));
    }
}
