import {
    AfterViewChecked,
    AfterViewInit,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    ViewChild
} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {MatSort} from '@angular/material/sort';
import {MatPaginator} from '@angular/material/paginator';
import {AdminReportGroupFilter} from '../../viewmodels/adminReportGroupFilter';
import {AdminReportFilteringService} from '../../services/admin-report-filtering.service';
import {BaseComponent} from '../base.component';
import {DateFormat, Dates, LoadingState, Title} from 'common';
import {TableCellInfoWrapper} from '../../wrappers/tableCellInfoWrapper';

@Component({
    selector: 'app-generic-admin-report-table',
    templateUrl: './generic-admin-report-table.component.html',
    styleUrls: ['./generic-admin-report-table.component.scss']
})
export class GenericAdminReportTableComponent extends BaseComponent implements OnInit, AfterViewInit, AfterViewChecked, OnChanges {
    @Input()
    public readonly dataSource: MatTableDataSource<any>;

    @Input()
    public columnData: Map<string, Title>;

    @Input()
    public loadingState: LoadingState;

    @Input()
    private viewModel: any;

    @Input()
    private modelLabel?: string;

    @Input()
    public searchLabel?: string;

    @Input()
    public truncateColumns?: string[];

    @Input()
    public filter: AdminReportGroupFilter;

    @ViewChild(MatSort)
    public sort: MatSort;

    @ViewChild(MatPaginator)
    public paginator: MatPaginator;

    @Input()
    public totalInfo: string;

    @Output()
    rowClickEvent: EventEmitter<any>;

    @Output()
    sqCellClick: EventEmitter<any>;

    public columnsName: string[];

    public columnsTitles: string[];

    constructor(private adminReportFilteringService: AdminReportFilteringService) {
        super();
        this.rowClickEvent = new EventEmitter<string>();
        this.sqCellClick = new EventEmitter<string>();
    }

    ngOnInit(): void {
        this.columnsName = Array.from(this.columnData.keys());
        this.columnsTitles = Array.from(this.columnData.values());
        this.dataSource.filterPredicate = this.adminReportFilteringService.filterPredicate;
        this.updateTotals();
    }

    public onRowClick(row: any): void {
        this.rowClickEvent.emit(row);
    }

    public onCellClick(element: string | number, column: string): void {
        this.sqCellClick.emit(new TableCellInfoWrapper(column, element[column]));
    }

    ngAfterViewInit(): void {
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
    }

    ngAfterViewChecked(): void {
        if (!this.dataSource.sort) {
            this.dataSource.sort = this.sort;
        }
    }

    ngOnChanges(): void {
        this.updateTotals();
    }

    private updateTotals(): void {
        const total = this.dataSource.data.length;
        const filtered = this.dataSource.filteredData.length;
        if (total === filtered) {
            this.totalInfo = `${total.toString()} ${this.modelLabel ?? ''}`;
        } else {
            this.totalInfo = `${filtered.toString()} of ${total.toString()} ${this.modelLabel ?? ''}`;
        }
    }

    public getName(element: string | number, column: string): string {
        let name = element[column];

        if (column === 'country' && name) {
            // @ts-ignore
            const regionNamesInEnglish = new Intl.DisplayNames(['EN'], {type: 'region'});
            name = regionNamesInEnglish.of(name);
        }

        if (column.includes('Date') && name) {
            name = name > 0 ? Dates.format(new Date(name), DateFormat.DATE_LONG) : '-';
        }
        return name ?? '-';
    }

    public formatElement(element: string | number, column: string): string {
        let name = element[column];
        if (column.includes('Date')) {
            name = element[column] > 0 ? Dates.format(new Date(element[column]), DateFormat.DATE_LONG) : undefined;
        }

        return name ?? '-';
    }

    public getColumnStyle(column: string): string | undefined {
        if (!this.truncateColumns) {
            return;
        }
        if (this.truncateColumns.includes(column) !== undefined) {
            return 'cell-value-ellipsis';
        }
    }

    public onFilterChanged(filter: AdminReportGroupFilter): void {
        this.dataSource.filter = JSON.stringify(filter);
        this.filter = filter;
        this.updateTotals();
    }
}
