import {ProtocolFindBySensorDataWrapper} from '../wrappers/protocolFindBySensorDataWrapper';

export class ProtocolFindBySensorDataViewModel {
    position: number;
    serialCode: string;
    notes: string;
    userCode: string;
    lastTimeUsedDate: number;
    firstTimeUsedDate: number;
    searchedAtDate: number;

    public static from(dataWrapper: ProtocolFindBySensorDataWrapper[]): ProtocolFindBySensorDataViewModel[] {
        const data = [];
        dataWrapper.forEach ((dataRow, i) => {
            const convertedData = new ProtocolFindBySensorDataViewModel();
            convertedData.notes = dataRow.notes;
            convertedData.position = i + 1;
            convertedData.userCode = dataRow.userCode;
            convertedData.serialCode = dataRow.serialCode;
            convertedData.lastTimeUsedDate = dataRow.lastTimeUsedDate;
            convertedData.firstTimeUsedDate = dataRow.firstTimeUsedDate;
            convertedData.searchedAtDate = dataRow.searchedAtDate;

            data.push(convertedData);
        });
        return data;
    }
}
