<div class="container mt-5">
    <h1>Users & Sensors</h1>
    <div class="accounts row">
        <div class="col-12 col-md-6 mb-3">
            <div class="section d-flex flex-column pt-2 ps-3 pe-3 mat-elevation-z2 full-width">
                <h3>Find users</h3>
                <div class="flex-grow-1">
                    <app-text-search
                        searchHint='Search by email'
                        type="email"
                        maxLength='50'
                        minLength='3'
                        (submitEvent)='onSubmit()'
                        [value]="searchValue"
                        (valueChange)="searchValue = $event"
                    >
                    </app-text-search>
                </div>
                <div class="actions d-flex justify-content-end">
                    <button class="m-2" mat-button (click)="onAllAccountsClick()">
                        View All (slow)
                    </button>
                    <button class="m-2" mat-button color="primary" type="submit" (click)="onSubmit()">Find Accounts
                    </button>
                </div>
            </div>
        </div>
        <div class="col-12 col-md-6 mb-3 d-flex flex-row align-items-stretch">
            <div class="section d-flex flex-column pt-2 ps-3 pe-3 mat-elevation-z2 full-width">
                <h3>Search for a Sensor</h3>
                <div class="flex-grow-1">Use the serial number from a sensor to find usage, analysis json and application log messages.</div>
                <div class="actions d-flex justify-content-end">
                    <button mat-button color="primary" class="m-2" (click)="onFindLatestUsageOfSensorsClick()">Find usage of sensors</button>
                    <button mat-button color="primary" class="m-2" (click)="onFindProductionLogsFromSensorClick()">Find production logs of sensor</button>
                    <button mat-button color="primary" class="m-2" (click)="onFindProtocolAnalysisFromSensorClick()">Find protocol analysis of sensor</button>
                </div>
            </div>
        </div>
    </div>
    <h1 class="mt-3 mt-lg-5">Statistics</h1>
    <div class="statistics row">
        <div class="col-12 col-md-6 mb-3">
            <div class="section d-flex flex-column pt-2 ps-3 pe-3 mat-elevation-z2 full-width">
                <h3>Sensor usage report</h3>
                <div class="flex-grow-1">View device usage statistics per user account. The report is generated once per day. The first time on the day it takes some time to be
                    created, consequent
                    times are fast.
                </div>
                <div class="actions d-flex justify-content-end">
                    <button mat-button color="primary" class="m-2" (click)="onStatisticsDeviceUsageReportClick()">View Report</button>
                </div>
            </div>
        </div>
        <div class="col-12 col-md-6 mb-3">
            <div class="section d-flex flex-column pt-2 ps-3 pe-3 mat-elevation-z2 full-width">
                <h3>Payments report</h3>
                <div class="flex-grow-1">View Payments statistics per user account. The report is generated once per day. The first time on the day it takes some time to be
                    created, consequent
                    times are fast.
                </div>
                <div class="actions d-flex justify-content-end">
                    <button mat-button color="primary" class="m-2" (click)="onStatisticsPaymentReportClick()">View Report</button>
                </div>
            </div>
        </div>
    </div>

</div>
