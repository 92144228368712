import { AccountAdminDto } from '../dtos/accountAdmin.dto';
export class AccountTableDataViewModel {
    code: string;
    position: number;
    fullName: string;
    email: string;
    createdOnDate: number;
    countryFull: string;

    constructor() {
    }

    public static from(accounts: AccountAdminDto[]): AccountTableDataViewModel[] {
        const data = [];
        accounts.forEach ((account, i) => {
            const convertedData = new AccountTableDataViewModel();
            convertedData.code = account.code;
            convertedData.position = i + 1;
            convertedData.fullName = account.fullName;
            convertedData.email = account.email;
            convertedData.createdOnDate = account.createdOn;
            // @ts-ignore
            const regionNamesInEnglish = new Intl.DisplayNames(['EN'], {type: 'region'});
            convertedData.countryFull = account.country ? regionNamesInEnglish.of(account.country) : account.country;
            data.push(convertedData);
        });

        return data;
    }
}
