<div class='container' *ngIf='loadingState === loadingStates.LOADED; else loadingComponent'>
    <div class='mb-3 pt-3'>
        <div class='h3'>Account Activity Report</div>
        <p>Created: {{dateCreated}}</p>
        <div class='lead'>
            With this report you can see:
            <ul>
                <li>the last use of the application,</li>
                <li>how many activities have been performed in the last 12 months,</li>
                <li>how many activities have been performed in the last 3 months</li>
                <li>and the usage of each device during the last 3 months</li>
            </ul>
        </div>
    </div>
    <app-generic-admin-report-table
        [filter]='filter'
        [columnData]='columns'
        [loadingState]='loadingState'
        [dataSource]='dataSource'
        [viewModel]='viewModel'
        modelLabel='Accounts'
        searchLabel='Search for Name, Email, Phone, Country Code'
        (rowClickEvent)='onRowClick($event)'
    ></app-generic-admin-report-table>
</div>
<ng-template #loadingComponent>
    <div class="container mt-5 d-flex flex-column align-items-center justify-content-center">
        <mat-progress-bar class='container' [value]='loadingProgress'></mat-progress-bar>
        <div>Loading Report Table... {{loadingStatus}}%</div>
    </div>
</ng-template>
