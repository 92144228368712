import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AccountDto, SubscriptionDto} from "common";

@Component({
    selector: 'app-account-subscriptions',
    templateUrl: './account-subscriptions.component.html',
    styleUrls: ['./account-subscriptions.component.scss']
})
export class AccountSubscriptionsComponent implements OnInit {
    @Input()
    public account: AccountDto;

    @Input()
    public subscriptions: SubscriptionDto[];

    @Output()
    public onAddSubscriptionPress: EventEmitter<void>;

    @Output()
    public onDeactivateSubscriptionPress: EventEmitter<SubscriptionDto>;

    constructor() {
        this.onAddSubscriptionPress = new EventEmitter<void>();
        this.onDeactivateSubscriptionPress = new EventEmitter<SubscriptionDto>();
    }

    ngOnInit(): void {
    }

    public getActiveSubscriptions(): SubscriptionDto[] {
        let activeSubscriptions = this.subscriptions.filter(subscription => subscription.enabled && !subscription.isExpired);

        return activeSubscriptions
            .sort((a, b) => a.createdOn - b.createdOn)
            .reverse();
    }

    getInactiveSubscriptions() {
        let inactiveSubscriptions = this.subscriptions.filter(subscription => !(subscription.enabled && !subscription.isExpired));
        return inactiveSubscriptions
            .sort((a, b) => a.createdOn - b.createdOn)
            .reverse();
    }
}
