import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {SubscriptionDto} from "common";

@Component({
    selector: 'app-subscriptions-panel',
    templateUrl: './subscriptions-panel.component.html',
    styleUrls: ['./subscriptions-panel.component.scss']
})
export class SubscriptionsPanelComponent implements OnInit {

    @Input()
    public subscriptions: SubscriptionDto[];

    @Output()
    public onDeactivate: EventEmitter<SubscriptionDto>;

    constructor() {
        this.onDeactivate = new EventEmitter<SubscriptionDto>();
    }

    ngOnInit(): void {
    }

    formatStatus(subscription: SubscriptionDto) {
        let status = '';
        if (subscription.enabled && !subscription.isExpired) {
            status = 'Active';
        } else if (!subscription.enabled) {
            status = 'Disabled';
        } else if (subscription.isExpired) {
            status = 'Expired';
        }

        return status;
    }
}
