import {Injectable} from '@angular/core';
import {AccountAdminService} from './api/account-admin.service';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {AccountAdminDto} from '../dtos/accountAdmin.dto';
import {BrowserStorageService, TextUtils} from "common";

@Injectable({
    providedIn: 'root',
})
export class AccountsController {
    public static readonly PERSISTENCE_KEY = 'accounts';

    constructor(private _accountAdminService: AccountAdminService, private storage: BrowserStorageService) {
    }

    public getAll(): Observable<AccountAdminDto[]> {
        // Return cached, if already loaded
        if (this.storage.exists('accounts')) {
            return new Observable<AccountAdminDto[]>(observer => {
                const accounts = this.storage.loadDtos(AccountsController.PERSISTENCE_KEY, AccountAdminDto);
                observer.next(accounts);
                observer.complete();
            });
        }

        // load for the first time
        return this._accountAdminService.allAccounts()
            .pipe(map(accounts => {
                this.storage.saveDtos(AccountsController.PERSISTENCE_KEY, accounts);
                return accounts;
            }));
    }

    public getByEmailLikeQuery(emailValue: string): Observable<AccountAdminDto[]> {
        let accountsObservable: Observable<AccountAdminDto[]>;
        if (this.storage.exists('accounts')) {
            accountsObservable = new Observable<AccountAdminDto[]>((observer) => {
                const accounts = this.storage.loadDtos(AccountsController.PERSISTENCE_KEY, AccountAdminDto);
                accounts.filter(account => {
                    // query
                    if (!TextUtils.isEmpty(emailValue)) {
                        let query = emailValue.toLowerCase();
                        let mail = account.email.toLowerCase();
                        return mail.includes(query);
                    }
                });

                observer.next(accounts);
                observer.complete();
            });
        } else {
            accountsObservable = this._accountAdminService.accountsByEmailLike(emailValue);
        }
        return accountsObservable;
    }
}
