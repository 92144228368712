<div class='container'>
    <div class='mb-3 pt-3'>
        <div class='h3'>Search production logs</div>
        <div class="flex-grow-1" style="margin-top: 24px;margin-bottom: 24px;">
            Append a single serial code and set from and to date ranges ( <b>Default range is 3 months</b> ).<br>
            This will search all the related production logs with this serial code and return their messages along with some
            user information. <br>
            Click on the <b>Message</b> to see a more detailed view.<br><br>
            ** Fetching this information may require some seconds to execute.
        </div>
        <div class="d-flex flex-row align-items-center">
            <app-text-search class="flex-grow-1"
                             [value]='searchValue'
                             [searchHint]='searchHint'
                             [minLength]='minLength'
                             (submitEvent)='onSubmit()'
                             (valueChange)='onValueChanced($event)'
            >
            </app-text-search>
            <div mat-line class="detail">
                <div class='h6' style="text-align: center">From</div>
                <mat-form-field appearance="fill" class="picker" style="margin-left: 20px;margin-right: 20px;">
                        <mat-label>(mm/dd/yyyy)</mat-label>
                        <input matInput
                               [matDatepicker]="picker"
                               [(ngModel)]="fromDate"
                               (dateChange)="onFromDateChanged($event)">
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                </mat-form-field>
            </div>
            <div mat-line class="detail">
                <div class='h6' style="text-align: center">To</div>
                <div>
                    <mat-form-field appearance="fill" class="picker" style="margin-left: 20px;margin-right: 20px;">
                        <mat-label>(mm/dd/yyyy)</mat-label>
                        <input matInput
                               [matDatepicker]="toPicker"
                               [(ngModel)]="toDate"
                               (dateChange)="onToDateChanged($event)">
                        <mat-datepicker-toggle matSuffix [for]="toPicker"></mat-datepicker-toggle>
                        <mat-datepicker #toPicker></mat-datepicker>
                    </mat-form-field>
                </div>
            </div>
            <button class="m-3" color="primary" mat-raised-button type='submit'
                    [disabled]='!canSearch()'
                    (click)="onSubmit()">Search
            </button>
        </div>
    </div>
    <app-generic-admin-report-table
        [filter]='filter'
        [columnData]='columns'
        [loadingState]='loadingState'
        [dataSource]='dataSource'
        [viewModel]='viewModel'
        [truncateColumns]='truncateColumns'
        modelLabel='Activities'
        searchLabel='Search Sensor Production Logs'
        (sqCellClick)='onCellClick($event)'>

    </app-generic-admin-report-table>
</div>
