export class ProtocolAnalysisBySensorDataWrapper {
    position: number;
    notes: string;
    accountEmail: string;
    searchedAtDate: number;
    summaryResults: string;
    activitiesResults: string;
    exerciseType: string;

    constructor(position: number,
                notes: string,
                userCode: string,
                searchedAtDate: number,
                summaryResults: string,
                activitiesResults: string,
                exerciseType: string, ) {
        this.position = position;
        this.notes = notes;
        this.accountEmail = userCode;
        this.searchedAtDate = searchedAtDate;
        this.summaryResults = summaryResults;
        this.activitiesResults = activitiesResults;
        this.exerciseType = exerciseType;
    }
}
