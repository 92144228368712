import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {map, takeUntil} from 'rxjs/operators';
import {MatTableDataSource} from '@angular/material/table';
import {MatSort} from '@angular/material/sort';
import {ActivatedRoute} from '@angular/router';
import {SerialCodeAdminService} from '../../services/api/serial-code-admin.service';
import {SerialCodeTableDataViewModel} from '../../viewmodels/serialCodeTableDataViewModel';
import {AccountAdminService} from '../../services/api/account-admin.service';
import {AdminNavigationService} from '../../services/admin-navigation.service';
import {AdminReportGroupFilter} from '../../viewmodels/adminReportGroupFilter';
import {BaseComponent} from '../base.component';
import {LoadingState, NotificationService} from 'common';
import {MAT_DIALOG_DATA, MatDialog} from '@angular/material/dialog';
import {TableCellInfoWrapper} from '../../wrappers/tableCellInfoWrapper';
import {ProtocolAnalysisBySensorOverviewResponseDto} from '../../dtos/protocolAnalysisBySensorOverviewResponseDto';
import {ProtocolAnalysisBySensorDataWrapper} from '../../wrappers/protocolAnalysisBySensorDataWrapper';
import {ProtocolAnalysisBySensorViewModel} from '../../viewmodels/protocolAnalysisBySensorViewModel';
import {DialogShowDetailedMessageComponent} from '../dialogs/dialog-show-detailed-message';

@Component({
    selector: 'app-serial-code',
    templateUrl: './find-sensor-protocol-analysis.component.html',
    styleUrls: ['./find-sensor-protocol-analysis.component.scss']
})
export class FindSensorProtocolAnalysisComponent extends BaseComponent implements OnInit {

    public showSearch: boolean;
    public readonly dataSource: MatTableDataSource<ProtocolAnalysisBySensorViewModel>;
    public columns: Map<string, string>;
    public loadingState: LoadingState;
    public viewModel: SerialCodeTableDataViewModel;
    public filter: AdminReportGroupFilter;
    public truncateColumns = ['activitiesResults', 'summaryResults'];

    @ViewChild(MatSort)
    public sort: MatSort;

    public searchValue: string;
    public searchHint: string;
    public readonly minLength = 7;

    constructor(private serialCodeService: SerialCodeAdminService,
                private accountAdminService: AccountAdminService,
                private notificationService: NotificationService,
                private route: ActivatedRoute,
                public dialog: MatDialog,
                private adminNavigation: AdminNavigationService
    ) {
        super();
        this.dataSource = new MatTableDataSource([]);
        this.searchValue = '';
        this.searchHint = 'Insert a serial code to find protocol analysis';
        this.showSearch = true;

        this.columns = new Map<string, string>();
        this.columns.set('position', 'No');
        this.columns.set('accountEmail', 'Email');
        this.columns.set('searchedAtDate', 'Searched At');
        this.columns.set('exerciseType', 'Exercise Types');
        this.columns.set('summaryResults', 'Summary');
        this.columns.set('activitiesResults', 'Results');
        this.columns.set('notes', 'Notes');

        this.filter = new AdminReportGroupFilter();
        this.filter.supportsQuery = false;
        this.filter.supportsSubscriptionPlanTypes = false;
        this.filter.supportsSubscriptionPeriodUnits = false;
        this.filter.supportsSubscriptionSources = false;
    }

    ngOnInit(): void {
        this.analyticsSwitchPage('Admin-FindSensorProtocolAnalysisComponent', '', '/admin/findSensorProtocolAnalysis');

        this.route.queryParams
            .pipe(takeUntil(this.destroySubject))
            .subscribe(params => {
                    if (!params.sensor) {
                        return;
                    }
                    this.searchValue = params.sensor;
                    this.search(true);
                }
            );
    }

    onSubmit(): void {
        this.search();
    }

    onValueChanced(value: string): void {
        this.searchValue = value.toUpperCase();
    }

    canSearch(): boolean {
        return this.showSearch && this.searchValue?.length >= this.minLength;
    }

    private search(fromInit?: boolean): void {
        if (!fromInit) {
            this.adminNavigation.goToFindProtocolAnalysisOfSensor(this.searchValue);
            return;
        }

        this.loadingState = LoadingState.LOADING;
        this.showSearch = false;

        this.serialCodeService.getProtocolAnalysisForSerialCode(this.searchValue)
            .pipe(takeUntil(this.destroySubject))
            .pipe(map((overviewDto: ProtocolAnalysisBySensorOverviewResponseDto) => {
                const dataWrappers: ProtocolAnalysisBySensorDataWrapper[] = [];

                if (overviewDto.analysis.length === 0) {
                    dataWrappers.push(new ProtocolAnalysisBySensorDataWrapper(0, overviewDto.notes, null, -1, null, null, null));
                } else {
                    Object.values(overviewDto.analysis).forEach((analysisDto, index) => {
                        dataWrappers.push(new ProtocolAnalysisBySensorDataWrapper(index, overviewDto.notes, analysisDto.accountEmail, overviewDto.dateOfSearch,
                            analysisDto.protocolSummaryResults, analysisDto.activitiesResults, analysisDto.activityExerciseType));
                    });
                }

                return dataWrappers;
            }))
            .subscribe(results => {
                this.dataSource.data = ProtocolAnalysisBySensorViewModel.from(results);

                this.loadingState = LoadingState.LOADED;
                this.showSearch = true;
            }, error => {
                    this.showSearch = true;
                    this.loadingState = LoadingState.ERROR;
                    this.notificationService.info(`Couldn't load serial code info`);
                    this.errorHandler.handle(error);
            });
    }

    openDialog(message: string): void {
        this.dialog.open(DialogShowDetailedMessageComponent, {
            data: {message, title: 'Analysis'}
        });
    }

    onCellClick(cell: TableCellInfoWrapper): void {
        if (this.truncateColumns.includes(cell.name)) {
            const cellValueJsonObject = JSON.parse(cell.value);
            this.openDialog(JSON.stringify(cellValueJsonObject, null, 4));
        }
    }
}
