/**
 * reflects account admin dto on server
 */
import {AccountRole, BaseDto, Gender, SpecialityType, SpecialityTypes, AccountMetaDto} from 'common';

export class AccountAdminDto extends BaseDto {
    /**
     * The token return from server at login
     */
    authToken?: string;
    email: string;
    firstName: string;
    lastName: string;
    gender: Gender;
    dateOfBirth: number;
    specialty: string;
    professionalRole: string;
    termsOfUseVersion: string;
    termsOfUseConsentOn: number;
    accountRole: string;
    country: string;
    telephone: string;
    telephoneCountryCode: string;
    zipCode: string;
    metaFields: AccountMetaDto[];

    public deserialize(input: any): this {
        Object.assign(this, input);
        this.metaFields = this.deserializeArray(input.metaFields, AccountMetaDto);
        return this;
    }

    public get accountRoleType(): AccountRole {
        return AccountRole[this.accountRole];
    }

    public get specialityType(): SpecialityType {
        return SpecialityTypes.parse(this.specialty);
    }

    public get fullName(): string {
        return `${this.firstName} ${this.lastName}`;
    }

    public getMeta(key: string): AccountMetaDto {
        if (!this.metaFields) {
            return undefined;
        }
        return Array.from(this.metaFields).find(metaField => metaField.metaKey && metaField.metaKey.toLowerCase() === key.toLowerCase());
    }

    public getMetaString(key: string): string {
        const metaValue = this.getMeta(key);
        if (!metaValue) {
            return '';
        }

        return metaValue.metaValue;
    }

    public clone(): AccountAdminDto {
        const c = new AccountAdminDto();
        c.code = this.code;
        c.createdOn = this.createdOn;
        c.deleted = this.deleted;
        c.authToken = this.authToken;
        c.email = this.email;
        c.firstName = this.firstName;
        c.lastName = this.lastName;
        c.gender = this.gender;
        c.dateOfBirth = this.dateOfBirth;
        c.specialty = this.specialty;
        c.professionalRole = this.professionalRole;
        c.termsOfUseVersion = this.termsOfUseVersion;
        c.termsOfUseConsentOn = this.termsOfUseConsentOn;
        c.accountRole = this.accountRole;
        c.country = this.country;
        c.telephone = this.telephone;
        c.telephoneCountryCode = this.telephoneCountryCode;
        c.zipCode = this.zipCode;
        c.metaFields = this.metaFields.map(m => m.clone());
        return c;
    }
}
