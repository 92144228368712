import {Component, Input, OnInit} from '@angular/core';
import {AccountRole, TextUtils, AccountMetaDto} from 'common';
import {AccountAdminDto} from '../../dtos/accountAdmin.dto';
import {AccountMetaKeys} from '../../models/accountMetaKeys';
import {AccountAdminService} from '../../services/api/account-admin.service';
import {BaseComponent} from '../base.component';
import {v4 as uuidv4} from 'uuid';
@Component({
    selector: 'app-account-info',
    templateUrl: './account-info.component.html',
    styleUrls: ['./account-info.component.scss']
})
export class AccountInfoComponent extends BaseComponent implements OnInit {

    constructor(private accountsService: AccountAdminService) {
        super();
    }

    public get salesmanPlaceholder(): string {
        return 'Enter salesman name..';
    }

    public get saveSalesmanVisible(): boolean {
        return this.salesman !== this.account.getMetaString(AccountMetaKeys.SALESMAN);
    }

    @Input()
    public account: AccountAdminDto;

    public salesman: string;

    ngOnInit(): void {
        this.salesman = this.account.getMetaString(AccountMetaKeys.SALESMAN);
    }

    public formatSpecialty(): string {
        if (TextUtils.isEmpty(this.account.specialty)) {
            return '-';
        }

        return this.account.specialty.replace('_', ' ').toLowerCase();
    }

    public formatCountryName(): string {
        let name = '-';
        if (this.account.country) {
            // @ts-ignore
            const regionNamesInEnglish = new Intl.DisplayNames(['EN'], {type: 'region'});
            name = regionNamesInEnglish.of(this.account.country);
        }
        return name;
    }

    public accountIsPatient(role: AccountRole): boolean {
        return role === AccountRole.PATIENT;
    }

    public saveSalesman(): void {
        const clone = this.account.clone();
        let newMeta = clone.getMeta(AccountMetaKeys.SALESMAN);
        if (!newMeta) {
            newMeta = new AccountMetaDto();
            newMeta.Code = uuidv4();
            newMeta.metaKey = AccountMetaKeys.SALESMAN;
            clone.metaFields.push(newMeta);
        }

        newMeta.metaValue = this.salesman;

        this.accountsService.updateAccountMetas(clone)
            .subscribe(response => {
                this.account = response;
                this.notification.info(`Saved Salesman!`);
            }, error => {
                this.notification.info(`Failed to save Salesman: ${error.statusText} (${error.status})`);
            });
    }
}
