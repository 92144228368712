<div class="container mt-4">
    <div *ngIf="loading === loadingStates.LOADED && this.account !== undefined" class="row">
        <aside class="col-3">
            <div class="p-4 mat-elevation-z5">
                <h1 class="participant-name mt-2">{{this.account.fullName}}</h1>
                <small>{{this.account.email}}</small>
                <mat-selection-list class="mt-4" #viewTabs multiple="false" (selectionChange)="onMainTabChange()">
                    <mat-list-option *ngFor="let data of menuItems | keyvalue" [value]="data.key" [selected]="currentView===data.key">
                        {{data.value}}
                    </mat-list-option>
                </mat-selection-list>
            </div>
        </aside>
        <main class="col-9" ngSwitch="{{this.currentView}}">
            <div *ngSwitchCase="viewType.INFO">
                <app-account-info [account]="this.account"></app-account-info>
            </div>
            <div *ngSwitchCase="viewType.SUBSCRIPTIONS">
                <app-account-subscriptions
                    [account]="this.account"
                    [subscriptions]="subscriptions"
                    (onAddSubscriptionPress)="onAddSubscriptionPress()"
                    (onDeactivateSubscriptionPress)="onDeactivateSubscription($event)"
                ></app-account-subscriptions>
            </div>
            <div *ngSwitchCase="viewType.PURCHASES">
                <app-account-purchases-info [purchases]="this.purchases"></app-account-purchases-info>
            </div>
        </main>
    </div>
</div>
