import {Component, OnInit, ViewChild} from '@angular/core';
import {map, takeUntil} from 'rxjs/operators';
import {MatTableDataSource} from '@angular/material/table';
import {MatSort} from '@angular/material/sort';
import {ActivatedRoute} from '@angular/router';
import {SerialCodeAdminService} from '../../services/api/serial-code-admin.service';
import {SerialCodeTableDataViewModel} from '../../viewmodels/serialCodeTableDataViewModel';
import {AccountAdminService} from '../../services/api/account-admin.service';
import {AdminNavigationService} from '../../services/admin-navigation.service';
import {AdminReportGroupFilter} from '../../viewmodels/adminReportGroupFilter';
import {BaseComponent} from '../base.component';
import {KeyValue, LoadingState, NotificationService} from 'common';
import {ProtocolFindBySensorDataViewModel} from '../../viewmodels/protocolFindBySensorReportViewModel';
import {ProtocolFindBySensorReportDto} from '../../dtos/protocolFindBySensorReportDto';
import {SerialCodeProgressDto} from '../../dtos/serialCodeProgressDto';
import {ProtocolFindBySensorDataWrapper} from '../../wrappers/protocolFindBySensorDataWrapper';

@Component({
    selector: 'app-serial-code',
    templateUrl: './find-sensor-latest-usage.component.html',
    styleUrls: ['./find-sensor-latest-usage.component.scss']
})
export class FindSensorLatestUsageComponent extends BaseComponent implements OnInit {

    public showSearch: boolean;
    public readonly dataSource: MatTableDataSource<ProtocolFindBySensorDataViewModel>;
    public columns: Map<string, string>;
    public loadingState: LoadingState;
    public viewModel: SerialCodeTableDataViewModel;
    public filter: AdminReportGroupFilter;

    @ViewChild(MatSort)
    public sort: MatSort;

    public searchValue: string;
    public searchHint: string;
    public readonly minLength = 7;
    public readonly maxLength = 39;

    constructor(private serialCodeService: SerialCodeAdminService,
                private accountAdminService: AccountAdminService,
                private notificationService: NotificationService,
                private route: ActivatedRoute,
                private adminNavigation: AdminNavigationService
    ) {
        super();
        this.dataSource = new MatTableDataSource([]);
        this.searchValue = '';
        this.searchHint = 'Insert up to 5 comma-separated sensors';
        this.showSearch = true;

        this.columns = new Map<string, string>();
        this.columns.set('position', 'No');
        this.columns.set('serialCode', 'Serial Code');
        this.columns.set('firstTimeUsedDate', 'First Usage');
        this.columns.set('lastTimeUsedDate', 'Last Usage');
        this.columns.set('userCode', 'User Code');
        this.columns.set('searchedAtDate', 'Searched At');
        this.columns.set('notes', 'State');

        this.filter = new AdminReportGroupFilter();
        this.filter.supportsQuery = false;
        this.filter.supportsSubscriptionPlanTypes = false;
        this.filter.supportsSubscriptionPeriodUnits = false;
        this.filter.supportsSubscriptionSources = false;
    }

    ngOnInit(): void {
        this.analyticsSwitchPage('Admin-FindSensorLatestUsage', '', '/admin/findSensorLatestUsage');

        this.route.queryParams
            .pipe(takeUntil(this.destroySubject))
            .subscribe(params => {
                if (!params.sensors) {
                    return;
                }
                this.searchValue = params.sensors;
                this.search(true);
            });
    }

    public onRowClick(row: ProtocolFindBySensorDataWrapper): void {
        this.adminNavigation.goToAccount(row.userCode)
            .then(() => Promise.resolve())
            .catch(err => this.notification.info(`go to account Failed: ${err.statusText} (${err.status})`));
    }

    onSubmit(): void {
        this.search();
    }

    onValueChanced(value: string): void {
        this.searchValue = value.toUpperCase();
    }

    canSearch(): boolean {
        return this.showSearch && this.searchValue?.length >= this.minLength;
    }

    private search(fromInit?: boolean): void {
        if (!fromInit) {
            this.adminNavigation.goToFindLatestUsageOfSensors(this.searchValue);
            return;
        }

        this.loadingState = LoadingState.LOADING;
        this.showSearch = false;
        const serialCodes = this.searchValue.split(',');
        let position = 0;

        this.serialCodeService.findLatestUsageForSerialCodes(serialCodes)
            .pipe(takeUntil(this.destroySubject))
            .pipe(map((progressDto: ProtocolFindBySensorReportDto) => {
                const pairs: KeyValue<string, SerialCodeProgressDto>[] = [];

                for (const serialCodeKey of Object.keys(progressDto.progress)) {
                    pairs.push(KeyValue.of(serialCodeKey, progressDto.progress[serialCodeKey]));
                }
                return pairs;
            }))
            .subscribe((results) => {
                const dataWrapper: ProtocolFindBySensorDataWrapper[] = [];

                results.forEach((value) => {
                    const serialCode = value.key;
                    const notes = value.value.notes;
                    const dateOfSearch = value.value.dateOfSearch;

                    if (value.value.infoDtos.length === 0) {
                        dataWrapper.push(new ProtocolFindBySensorDataWrapper(position, serialCode, notes, '--', -1, -1, dateOfSearch));
                        position++;
                    } else {
                        value.value.infoDtos.forEach(infoDto => {
                            dataWrapper.push(new ProtocolFindBySensorDataWrapper(position, serialCode, notes,
                                infoDto.userCode, infoDto.lastTimeUsed, infoDto.firstTimeUsed, dateOfSearch));
                            position++;
                        });
                    }

                    this.dataSource.data = ProtocolFindBySensorDataViewModel.from(dataWrapper);
                    this.loadingState = LoadingState.LOADED;
                    this.showSearch = true;
                });


            }, error => {
                this.loadingState = LoadingState.ERROR;
                this.showSearch = true;
                this.notificationService.info(`Couldn't load serial code info`);
                this.errorHandler.handle(error);
            });
    }
}
