import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, Subject} from "rxjs";
import {AccountDto} from "common";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {BrowserStorageService} from "common";
import {AdminNavigationService} from "./services/admin-navigation.service";
import {map, mergeMap} from "rxjs/operators";
import {environment} from "../environments/environment";

@Injectable({
    providedIn: 'root'
})
export class AuthenticationService {
    public static readonly PERSISTENCE_USER_ACCOUNT_KEY = 'account';

    private readonly _accountSubject: BehaviorSubject<AccountDto>;

    constructor(private httpClient: HttpClient, private storage: BrowserStorageService, private navigation: AdminNavigationService) {
        this._accountSubject = new BehaviorSubject<AccountDto>(storage.loadDto(AuthenticationService.PERSISTENCE_USER_ACCOUNT_KEY, AccountDto));
    }

    public login(email: string, password: string): Observable<AccountDto> {
        // before login in, log user out. Accidental visits to log-in component shouldn't log out user, but only when they request to act.
        this.logout();

        const authData = window.btoa(email + ':' + password);
        return this.httpClient.post<any>(this.apiUrl + 'api/authorization/login', null, {
            headers: new HttpHeaders({
                'Authorization': 'Basic ' + authData
            })
        }).pipe(mergeMap(response => {
            // Response contains the token. Create an empty account having only the token.
            // It will be consumed by authGuard and will be included in the /self request for authentication
            const accountDto = new AccountDto();
            accountDto.authToken = response.token;
            this.accountSubject.next(accountDto);
            this.saveAccountData();
            return this.getSelf();
        })).pipe(map(account => {
            // Account is now updated with the response from /self
            this.accountSubject.next(account);
            this.saveAccountData();
            return this.account;
        }));
    }

    private get apiUrl(): string {
        return environment.apiUrl;
    }

    public get account(): AccountDto {
        return this._accountSubject.value;
    }

    public get accountSubject(): Subject<AccountDto> {
        return this._accountSubject;
    }

    private saveAccountData(): void {
        this.storage.saveDto(AuthenticationService.PERSISTENCE_USER_ACCOUNT_KEY, this.account);
    }

    private getSelf(): Observable<AccountDto> {
        return this.httpClient.get<any>(this.apiUrl + 'api/authorization/self')
            .pipe(map(selfData => {
                let accountDto = new AccountDto().deserialize(selfData);
                accountDto.authToken = this.account.authToken;
                this._accountSubject.next(accountDto);
                this.saveAccountData();
                return accountDto;
            }));
    }

    public logout(): void {
        console.log(`Logging out`);
        this.storage.deleteAll();
        this.accountSubject.next(null);
        this.navigation.goToLogin();
    }
}
