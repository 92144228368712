import {DeviceSessions} from "../models/DeviceSessions";
import {AccountAdminDto} from "./accountAdmin.dto";
import {Dto} from "common";
import {RecentAccountActivitySubscriptionDto} from "./recentAccountActivitySubscription.dto";

export class RecentAccountActivityDto extends Dto {
    account: AccountAdminDto;
    sessionsOneWeek: number;
    sessionsOneMonth: number;
    sessionsThreeMonths: number;
    sessionsTwelveMonths: number;
    lastUsed: number;
    deviceSessions: DeviceSessions[];
    subscriptions: RecentAccountActivitySubscriptionDto[];

    public deserialize(input: any): this {
        Object.assign(this, input);

        this.account = new AccountAdminDto().deserialize(input.account);
        this.deviceSessions = this.deserializeArray(input.deviceSessions, DeviceSessions);
        this.subscriptions = this.deserializeArray(input.subscriptions, RecentAccountActivitySubscriptionDto)

        return this;
    }
}
