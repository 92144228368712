export enum SubscriptionSource {
    ESHOP = "ESHOP",
    APPLE = "APPLE",
    CLOUD_ADMIN = "CLOUD_ADMIN"
}

export class SubscriptionSourceUtils {

    public static parse(subscription: string): SubscriptionSource {
        return SubscriptionSource[subscription as keyof typeof SubscriptionSource];
    }

    public static getAll(): SubscriptionSource[] {
        return Object.values(SubscriptionSource)
    }

    public static title(subscription: SubscriptionSource): string {
        let title: string;
        switch (subscription) {
            case SubscriptionSource.ESHOP:
                title = 'E-shop';
                break;
            case SubscriptionSource.APPLE:
                title = 'Apple Store';
                break;
            case SubscriptionSource.CLOUD_ADMIN:
                title = 'Cloud Admin';
                break;
            default:
                throw Error(`not existing type ${subscription}`)
        }
        return title;
    }
}
