import {Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {AccountAdminService} from "../../services/api/account-admin.service";
import {SubscriptionAdminService} from "../../services/api/subscription-admin.service";
import {AccountViewType} from "../../viewmodels/accountViewType";
import {MatSelectionList} from "@angular/material/list";
import {takeUntil} from "rxjs/operators";
import {AdminNavigationService} from "../../services/admin-navigation.service";
import {AccountAdminDto} from "../../dtos/accountAdmin.dto";
import {SubscriptionHistoryDto} from "../../dtos/subscriptionHistory.dto";
import {BaseComponent} from "../base.component";
import {LoadingState, SubscriptionDto} from "common";
import {BreadcrumbInitializerService} from "../../services/breadcrumb-initializer.service";

@Component({
    selector: 'app-account',
    templateUrl: './account.component.html',
    styleUrls: ['./account.component.scss']
})
export class AccountComponent extends BaseComponent implements OnInit {
    private static DEFAULT_VIEW = AccountViewType.INFO;

    public account: AccountAdminDto;
    public subscriptions: SubscriptionDto[];
    public purchases: SubscriptionHistoryDto[];

    @ViewChild('viewTabs') viewTabs: MatSelectionList;
    public loading: LoadingState;
    public currentView: AccountViewType;
    public readonly viewType: typeof AccountViewType = AccountViewType;
    public readonly menuItems: Map<AccountViewType, string>;

    constructor(private route: ActivatedRoute,
                private _accountAdminService: AccountAdminService,
                private _subscriptionAdminService: SubscriptionAdminService,
                private adminNavigation: AdminNavigationService,
                private breadcrumbInitializerService: BreadcrumbInitializerService
    ) {
        super();
        this.menuItems = new Map<AccountViewType, string>([
            [AccountViewType.INFO, 'Info'],
            [AccountViewType.SUBSCRIPTIONS, 'Subscriptions'],
            [AccountViewType.PURCHASES, 'Purchases'],
        ]);
        this.currentView = AccountComponent.DEFAULT_VIEW;
        this.subscriptions = [];
        this.purchases = [];
    }

    ngOnInit(): void {
        this.analyticsSwitchPage('Admin-Accounts-Account', '', '/admin/accounts/account');

        // get the account code
        this.route.paramMap
            .pipe(takeUntil(this.destroySubject))
            .subscribe((params) => {
                // validate token is uuid
                const accountCode = params.get('code');

                this.loading = LoadingState.LOADING;
                this._accountAdminService.get(accountCode)
                    .pipe(takeUntil(this.destroySubject))
                    .subscribe(account => {
                        this.account = account;
                        this.breadcrumbInitializerService.setAccountName(account.fullName);
                        this.loading = LoadingState.LOADED;
                    }, error => {
                        this.notification.info(`Get Account Failed: ${error.statusText} (${error.status})`);
                        this.loading = LoadingState.ERROR;
                    });

                // get subscriptions
                this._subscriptionAdminService.getForAccount(accountCode)
                    .pipe(takeUntil(this.destroySubject))
                    .subscribe(subscriptions => {
                        this.subscriptions = subscriptions;
                    }, error => {
                        this.notification.info(`Get Subscriptions Failed: ${error.statusText} (${error.status})`);
                    });
                // get purchases
                this._subscriptionAdminService.getPurchasesHistory(accountCode)
                    .pipe(takeUntil(this.destroySubject))
                    .subscribe(purchasesHistory => {
                        this.purchases = purchasesHistory;
                    }, error => {
                        this.notification.info(`Get Subscriptions Failed: ${error.statusText} (${error.status})`);
                    });
            });
    }

    onDeactivateSubscription(subscription: SubscriptionDto): void {
        this._subscriptionAdminService.deactivateSubscription(subscription.code)
            .pipe(takeUntil(this.destroySubject))
            .subscribe(_response => {
                this.onDeactivateSuccess(subscription);
            }, error => {
                this.notification.info(`Deactivate Subscription Failed: ${error.statusText} (${error.status})`);
            });
    }

    private onDeactivateSuccess(subscription: SubscriptionDto) {
        // Update the item in the list
        let subscriptionIndex = this.subscriptions.findIndex(value => value.code === subscription.code);
        subscription.enabled = false;
        this.subscriptions[subscriptionIndex] = subscription;
    }

    onAddSubscriptionPress() {
        this.adminNavigation.goToAccountAddSubscription(this.account.code);
    }

    onMainTabChange() {
        this.currentView = this.viewTabs.selectedOptions.selected[0]?.value;
    }
}
