import {AdminReportStatusDto} from "./adminReportStatus.dto";
import {Dto} from "common";
import {SubscriptionPaymentsHistoryDto} from "./subscriptionPaymentsHistory.dto";

/**
 * reflects recentAccountPaymentHistoryReportDto dto on server
 */
export class recentAccountPaymentHistoryReportDto extends Dto {
    reportStatus: AdminReportStatusDto;
    subscriptionPayments: SubscriptionPaymentsHistoryDto[];

    public deserialize(input: any): this {
        Object.assign(this, input);

        this.subscriptionPayments = this.deserializeArray(input.subscriptionPayments, SubscriptionPaymentsHistoryDto);

        return this;
    }
}
