import {BaseDto} from "common";
import {AccountAdminDto} from "./accountAdmin.dto";
import {RecentAccountActivitySubscriptionDto} from "./recentAccountActivitySubscription.dto";

/**
 * reflects SubscriptionPaymentsHistoryDto dto on server
 */
export class SubscriptionPaymentsHistoryDto extends BaseDto {
    account: AccountAdminDto;
    subscriptions: RecentAccountActivitySubscriptionDto[];

    public deserialize(input: any): this {
        Object.assign(this, input);

        this.account = new AccountAdminDto().deserialize(input.account);
        this.subscriptions = this.deserializeArray(input.subscriptions, RecentAccountActivitySubscriptionDto)

        return this;
    }
}
