import {Injectable} from '@angular/core';
import {AdminReportGroupFilter} from '../viewmodels/adminReportGroupFilter';
import {RecentAccountActivityReportDataViewModel} from '../viewmodels/recentAccountActivityReportDataViewModel';
import {AccountTableDataViewModel} from '../viewmodels/accountTableDataViewModel';
import {SubscriptionSourceUtils} from '../models/subscriptionSource';
import {SubscriptionPeriodUnitUtils, SubscriptionPlanTypeUtils} from "common";
import {RecentAccountPaymentsReportDataViewModel} from "../viewmodels/recentAccountPaymentsReportDataViewModel";

@Injectable({
    providedIn: 'root'
})
export class AdminReportFilteringService {

    constructor() {
    }

    public filterPredicate(vm: any, filter: string): boolean {
        const adminReportFilter: AdminReportGroupFilter = JSON.parse(filter);
        let exists = true;

        if (vm instanceof RecentAccountActivityReportDataViewModel) {
            if (adminReportFilter.query.length > 0) {
                const query = adminReportFilter.query.toLowerCase();
                // At least one subscription plan type should exist
                exists = vm.fullName?.toLowerCase().includes(query) ||
                    vm.email?.toLowerCase().includes(query) ||
                    vm.phone?.toLowerCase().includes(query) ||
                    vm.country?.toLowerCase().includes(query);
            }

            if (adminReportFilter.subscriptionPlanTypes.length > 0) {
                // At least one subscription plan type should exist
                exists = exists && adminReportFilter.subscriptionPlanTypes.map(t => SubscriptionPlanTypeUtils.title(t)).some(d => vm.subscription === d);
            }

            if (adminReportFilter.subscriptionPeriodUnits.length > 0) {
                // At least one subscription plan type should exist
                exists = exists && adminReportFilter.subscriptionPeriodUnits.map(t => SubscriptionPeriodUnitUtils.title(t)).some(d => vm.subscriptionPeriodUnit === d);
            }

            if (adminReportFilter.subscriptionSources.length > 0) {
                // At least one subscription plan type should exist
                exists = exists && adminReportFilter.subscriptionSources.map(t => SubscriptionSourceUtils.title(t)).some(d => vm.subscriptionSource === d);
            }
        } else if (vm instanceof AccountTableDataViewModel) {
            if (adminReportFilter.query.length > 0) {
                const query = adminReportFilter.query.toLowerCase();
                // At least one subscription plan type should exist
                exists = vm.fullName?.toLowerCase().includes(query) || vm.email?.toLowerCase().includes(query) || vm.countryFull?.toLowerCase().includes(query);
            }
        } else if (vm instanceof RecentAccountPaymentsReportDataViewModel) {
            if (adminReportFilter.query.length > 0) {
                const query = adminReportFilter.query.toLowerCase();
                // At least one subscription plan type should exist
                exists = vm.fullName?.toLowerCase().includes(query) ||
                    vm.email?.toLowerCase().includes(query);
            }

            if (adminReportFilter.subscriptionPlanTypes.length > 0) {
                // At least one subscription plan type should exist
                exists = exists && adminReportFilter.subscriptionPlanTypes.map(t => SubscriptionPlanTypeUtils.title(t)).some(d => vm.subscription?.toLowerCase() === d.toLowerCase());
            }

            if (adminReportFilter.subscriptionPeriodUnits.length > 0) {
                // At least one subscription plan type should exist
                exists = exists && adminReportFilter.subscriptionPeriodUnits.map(t => SubscriptionPeriodUnitUtils.title(t)).some(d => vm.subscriptionPeriodUnit?.toLowerCase()
                    === d.toLowerCase());
            }

            if (adminReportFilter.subscriptionSources.length > 0) {
                // At least one subscription plan type should exist
                exists = exists && adminReportFilter.subscriptionSources.map(t => SubscriptionSourceUtils.title(t)).some(d => vm.subscriptionSource?.toLowerCase() === d.toLowerCase());
            }
        }

        return exists;
    }
}
