import {Component} from '@angular/core';
import {BaseComponent} from "./components/base.component";

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent extends BaseComponent {
    title = 'admin';

    public loggedIn(): boolean {
        return this.authenticationService.account !== null;
    }
}
