<div *ngIf='loadingState === loadingStates.LOADED; else loadingComponent' class='container'>
    <div class='mb-2'>
        <app-admin-report-filters class="col-3" [searchLabel]='searchLabel' [filter]='filter' (filterChanged)='onFilterChanged($event)'>
        </app-admin-report-filters>
    </div>
    <mat-divider></mat-divider>
    <div class='content pt-3 pb-4 full-width'>
        <div class='top-panel d-flex align-items-center pb-3 pt-2'>
            <button mat-raised-button class="mr-1" (click)='exporter.exportTable("csv")'>Export Csv</button>
            <span class='pe-2'></span>
            <button mat-raised-button class="mr-1" (click)='exporter.exportTable("xlsx")'>Export Excel</button>
            <span class='fill-space'></span>
            <span class='info'>{{totalInfo}}</span>
        </div>
        <table mat-table [dataSource]='dataSource' matSort matTableExporter #exporter='matTableExporter'
               class='accounts-table full-width mat-elevation-z4'>
            <ng-container [matColumnDef]='column' *ngFor='let column of columnsName; let i = index'>
                <th mat-header-cell *matHeaderCellDef mat-sort-header>{{columnsTitles[i]}}</th>
                <td mat-cell *matCellDef='let element' class="ps-md-2" (click)="onCellClick(element, column);" [title]="getName(element,column)">
                    <span [class]="getColumnStyle(column)">
                    {{formatElement(element, column)}}
                    </span>
                </td>
            </ng-container>
            <tr class='mat-row' *matNoDataRow>
                <td class='mat-cell' colspan='4'>No results</td>
            </tr>
            <tr mat-header-row *matHeaderRowDef='columnsName; sticky: true'></tr>
            <tr class='index-cell' mat-row *matRowDef='let row; columns: columnsName;' (click)='onRowClick(row)'></tr>
        </table>
        <mat-paginator [pageSizeOptions]='[10,20,40,50]'
                       showFirstLastButtons
                       aria-label="Select page">
        </mat-paginator>
    </div>
</div>
<ng-template #loadingComponent>
    <div>
        {{loadingState}}
    </div>
</ng-template>

