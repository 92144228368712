<div class="container full-height">
    <div class='mb-3 pt-3'>
        <div class='h3'>Kforce User Accounts</div>
    </div>
    <app-generic-admin-report-table
        [filter]="filter"
        [columnData]='columns'
        [loadingState]='loadingState'
        [dataSource]='dataSource'
        [viewModel]='viewModel'
        modelLabel='Accounts'
        searchLabel='Search for Name, Email, Country'
        (rowClickEvent)="onRowClick($event)"
    ></app-generic-admin-report-table>
</div>
