import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {environment} from '../../../environments/environment';
import {ProtocolFindBySensorReportDto} from '../../dtos/protocolFindBySensorReportDto';
import {ProtocolFindProductionLogsBodyDto} from '../../dtos/protocolFindProductionLogsBodyDto';
import {ProtocolAnalysisBySensorOverviewResponseDto} from '../../dtos/protocolAnalysisBySensorOverviewResponseDto';

@Injectable({
    providedIn: 'root'
})
export class SerialCodeAdminService {

    constructor(private httpClient: HttpClient) {
    }

    public findLatestUsageForSerialCodes(serialCodes: string[]): Observable<ProtocolFindBySensorReportDto> {
        const httpParams = new HttpParams().set('serialCodes', serialCodes.toString());
        return this.httpClient.get<any>(environment.apiUrl + 'api/protocols/admin/v1/findBySerialCodes', {params: httpParams})
            .pipe(map(protocolFindBySensorReportDto => {
                return protocolFindBySensorReportDto;
            }));
    }

    public findProductionLogsForSerialCode(bodyDto: ProtocolFindProductionLogsBodyDto): Observable<any> {
        return this.httpClient.post<any>(environment.apiUrl + 'api/integration/bigquery/v1/query', bodyDto)
            .pipe(map(protocolFindProductionLogsResponseDto => {
                return protocolFindProductionLogsResponseDto;
            }));
    }

    public getProtocolAnalysisForSerialCode(serialCode: string): Observable<ProtocolAnalysisBySensorOverviewResponseDto> {
        const httpParams = new HttpParams().set('serialCode', serialCode.toString());
        return this.httpClient.get<any>(environment.apiUrl + 'api/protocols/admin/v1/analyzeBySerialCode', {params: httpParams})
            .pipe(map(protocolAnalysisBySensorResponseDto => {
                return protocolAnalysisBySensorResponseDto;
            }));
    }
}
