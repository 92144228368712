<div class='container'>
    <div class='mb-3 pt-3'>
        <div class='h3'>Find Protocol Analysis</div>
        <div class="flex-grow-1" style="margin-top: 24px;margin-bottom: 24px;">
            Append a single serial code and proceed.<br>
            This will search the <b>20 most recent</b> related protocols with this serial code and return their protocol summary results along with the
            analysis of their equivalent activities.<br>
            Click on the <b>Summary</b> and <b>Results</b> to see a more detailed view.<br><br>
            ** Fetching this information may require some seconds to execute.
        </div>
        <div class="d-flex flex-row align-items-center">
            <app-text-search class="flex-grow-1"
                             [value]='searchValue'
                             [searchHint]='searchHint'
                             [minLength]='minLength'
                             (submitEvent)='onSubmit()'
                             (valueChange)='onValueChanced($event)'
            >
            </app-text-search>
            <button class="m-3" color="primary" mat-raised-button type='submit'
                    [disabled]='!canSearch()'
                    (click)="onSubmit()">Search
            </button>
        </div>
    </div>
    <app-generic-admin-report-table
        [filter]='filter'
        [columnData]='columns'
        [loadingState]='loadingState'
        [dataSource]='dataSource'
        [viewModel]='viewModel'
        [truncateColumns]='truncateColumns'
        modelLabel='Activities'
        searchLabel='Search Sensor Production Logs'
        (sqCellClick)='onCellClick($event)'>

    </app-generic-admin-report-table>
</div>
