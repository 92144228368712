import {ProtocolAnalysisBySensorDataWrapper} from '../wrappers/protocolAnalysisBySensorDataWrapper';

export class ProtocolAnalysisBySensorViewModel {
    position: number;
    notes: string;
    accountEmail: string;
    searchedAtDate: number;
    summaryResults: string;
    activitiesResults: string;
    exerciseType: string;

    public static from(dataWrapper: ProtocolAnalysisBySensorDataWrapper[]): ProtocolAnalysisBySensorViewModel[] {
        const data = [];
        dataWrapper.forEach ((dataRow, i) => {
            const convertedData = new ProtocolAnalysisBySensorViewModel();
            convertedData.notes = dataRow.notes;
            convertedData.position = i + 1;
            convertedData.accountEmail = dataRow.accountEmail;
            convertedData.searchedAtDate = dataRow.searchedAtDate;
            convertedData.summaryResults = dataRow.summaryResults;
            convertedData.activitiesResults = dataRow.activitiesResults;
            convertedData.exerciseType = dataRow.exerciseType;

            data.push(convertedData);
        });
        return data;
    }
}
