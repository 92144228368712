import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from "@angular/common/http";
import {map} from "rxjs/operators";
import {Observable} from "rxjs";
import {AccountAdminDto} from "../../dtos/accountAdmin.dto";
import {environment} from "../../../environments/environment";
import {AccountRole} from "common";

@Injectable({
    providedIn: 'root'
})
export class AccountAdminService {

    constructor(private _httpClient: HttpClient) {
    }

    public allAccounts(): Observable<AccountAdminDto[]> {
        return this._httpClient.get<any>(environment.apiUrl + 'api/accounts/admin/v1')
            .pipe(map(accountsData => {
                let accounts: AccountAdminDto[] = [];
                for (const datum of (accountsData as AccountAdminDto[])) {
                    if (datum.accountRole === AccountRole.PATIENT) {
                        continue;
                    }
                    accounts.push(new AccountAdminDto().deserialize(datum));
                }
                return accounts;
            }));
    }

    public accountsByEmailLike(query: string): Observable<AccountAdminDto[]> {
        let httpParams = new HttpParams();
        httpParams = httpParams.append('query', query);

        return this._httpClient.get<any>(environment.apiUrl + 'api/accounts/admin/v1/emailLike',
            {params: httpParams})
            .pipe(map(accountsData => {
                const accounts: AccountAdminDto[] = [];
                for (const datum of (accountsData as any)) {
                    if (datum.accountRole === AccountRole.PATIENT) {
                        continue;
                    }
                    accounts.push(new AccountAdminDto().deserialize(datum));
                }
                return accounts;
            }));
    }

    public get(accountCode: string): Observable<AccountAdminDto> {
        return this._httpClient.get<any>(environment.apiUrl + 'api/accounts/admin/v1/' + accountCode)
            .pipe(map(accountData => {
                return new AccountAdminDto().deserialize(accountData);
            }));
    }

    public updateAccountMetas(accounDto: AccountAdminDto): Observable<AccountAdminDto> {
        return this._httpClient.put<any>(environment.apiUrl + 'api/accounts/admin/v1/updateMetas', accounDto)
            .pipe(map(accountData => {
                return new AccountAdminDto().deserialize(accountData);
            }));
    }
}
