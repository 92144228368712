import {Component, OnInit, ViewChild} from '@angular/core';
import {map, takeUntil} from 'rxjs/operators';
import {MatTableDataSource} from '@angular/material/table';
import {MatSort} from '@angular/material/sort';
import {ActivatedRoute} from '@angular/router';
import {SerialCodeAdminService} from '../../services/api/serial-code-admin.service';
import {SerialCodeTableDataViewModel} from '../../viewmodels/serialCodeTableDataViewModel';
import {AccountAdminService} from '../../services/api/account-admin.service';
import {AdminNavigationService} from '../../services/admin-navigation.service';
import {AdminReportGroupFilter} from '../../viewmodels/adminReportGroupFilter';
import {BaseComponent} from '../base.component';
import {LoadingState, NotificationService} from 'common';
import {MatDatepickerInputEvent} from '@angular/material/datepicker';
import {ProtocolFindProductionLogsBodyDto} from '../../dtos/protocolFindProductionLogsBodyDto';
import {ProtocolFindProductionLogsResponseDto} from '../../dtos/protocolFindProductionLogsResponseDto';
import {ProtocolFindProductionLogsViewModel} from '../../viewmodels/protocolFindProductionLogsViewModel';
import {MatDialog} from '@angular/material/dialog';
import {ProtocolFindProductionLogsDataWrapper} from '../../wrappers/protocolFindProductionLogsDataWrapper';
import {TableCellInfoWrapper} from '../../wrappers/tableCellInfoWrapper';
import {DialogShowDetailedMessageComponent} from '../dialogs/dialog-show-detailed-message';

@Component({
    selector: 'app-serial-code',
    templateUrl: './find-sensor-production-logs.component.html',
    styleUrls: ['./find-sensor-production-logs.component.scss']
})
export class FindSensorProductionLogsComponent extends BaseComponent implements OnInit {

    public showSearch: boolean;
    public readonly dataSource: MatTableDataSource<ProtocolFindProductionLogsViewModel>;
    public columns: Map<string, string>;
    public loadingState: LoadingState;
    public viewModel: SerialCodeTableDataViewModel;
    public filter: AdminReportGroupFilter;
    public truncateColumns = ['message'];

    @ViewChild(MatSort)
    public sort: MatSort;

    public searchValue: string;
    public searchHint: string;
    public readonly minLength = 7;

    public fromDate: Date;
    public toDate: Date;

    constructor(private serialCodeService: SerialCodeAdminService,
                private accountAdminService: AccountAdminService,
                private notificationService: NotificationService,
                private route: ActivatedRoute,
                public dialog: MatDialog,
                private adminNavigation: AdminNavigationService
    ) {
        super();
        this.dataSource = new MatTableDataSource([]);
        this.searchValue = '';
        this.searchHint = 'Insert a serial code to search production logs';
        this.showSearch = true;

        this.fromDate = new Date(
            new Date().getFullYear(),
            new Date().getMonth() - 3,
            new Date().getDate()
        );
        this.toDate = new Date();

        this.columns = new Map<string, string>();
        this.columns.set('position', 'No');
        this.columns.set('message', 'Message');
        this.columns.set('timestampDate', 'Date');
        this.columns.set('userCode', 'User Code');
        this.columns.set('product', 'Product');
        this.columns.set('productVersion', 'Product Version');
        this.columns.set('application', 'Application');
        this.columns.set('applicationVersion', 'Application Version');
        this.columns.set('deviceModel', 'Device Model');

        this.filter = new AdminReportGroupFilter();
        this.filter.supportsQuery = false;
        this.filter.supportsSubscriptionPlanTypes = false;
        this.filter.supportsSubscriptionPeriodUnits = false;
        this.filter.supportsSubscriptionSources = false;
    }

    ngOnInit(): void {
        this.analyticsSwitchPage('Admin-FindSensorProductionLogsComponent', '', '/admin/findSensorProductionLogs');

        this.route.queryParams
            .pipe(takeUntil(this.destroySubject))
            .subscribe(params => {
                    if (!params.sensor || !params.startDate || !params.endDate) {
                        return;
                    }
                    this.searchValue = params.sensor;
                    this.fromDate = new Date(Number(params.startDate));
                    this.toDate = new Date(Number(params.endDate));
                    this.search(true);
                }
            );
    }

    onSubmit(): void {
        this.search();
    }

    onValueChanced(value: string): void {
        this.searchValue = value.toUpperCase();
    }

    canSearch(): boolean {
        return this.showSearch && this.searchValue?.length >= this.minLength && this.fromDate !== null && this.toDate !== null;
    }

    onFromDateChanged = (event: MatDatepickerInputEvent<unknown, unknown | null>) => {
        if (!event.value) {
            return;
        }
        this.fromDate = new Date(event.value.toString());
    }

    onToDateChanged = (event: MatDatepickerInputEvent<unknown, unknown | null>) => {
        if (!event.value) {
            return;
        }
        this.toDate = new Date(event.value.toString());
    }

    private search(fromInit?: boolean): void {
        if (!fromInit) {
            this.adminNavigation.goToFindProductionLogsOfSensor(this.searchValue, this.fromDate.getTime(), this.toDate.getTime());
            return;
        }

        this.loadingState = LoadingState.LOADING;
        this.showSearch = false;

        this.serialCodeService.findProductionLogsForSerialCode(
            {startDate: this.fromDate.getTime(), endDate: this.toDate.getTime(), messageQuery: this.searchValue} as ProtocolFindProductionLogsBodyDto)
            .pipe(takeUntil(this.destroySubject))
            .pipe(map((progressDto: ProtocolFindProductionLogsResponseDto) => {
                const dataWrappers: ProtocolFindProductionLogsDataWrapper[] = [];

                Object.values(progressDto.dataRows).forEach((dataRow, index) => {
                    dataWrappers.push(new ProtocolFindProductionLogsDataWrapper(index, dataRow.product, dataRow.application, dataRow.timestamp,
                        dataRow.message, dataRow.accountCode, dataRow.deviceModel, dataRow.productVersion, dataRow.applicationVersion));
                });

                return dataWrappers;
            }))
            .subscribe(results => {
                this.dataSource.data = ProtocolFindProductionLogsViewModel.from(results);

                this.loadingState = LoadingState.LOADED;
                this.showSearch = true;
            }, error => {
                    this.showSearch = true;
                    this.loadingState = LoadingState.ERROR;
                    this.notificationService.info(`Couldn't load serial code info`);
                    this.errorHandler.handle(error);
            });
    }

    openDialog(message: string): void {
        const formattedMessage = message.split('}}').join('\n');
        this.dialog.open(DialogShowDetailedMessageComponent, {
            data: {message: formattedMessage, title: 'Message'},
        });
    }

    onCellClick(cell: TableCellInfoWrapper): void {
        if (cell.name === 'message') {
            this.openDialog(cell.value);
        } else if (cell.name === 'userCode') {
            this.adminNavigation.goToAccount(cell.value)
                .then(() => Promise.resolve())
                .catch(err => this.notification.info(`go to account Failed: ${err.statusText} (${err.status})`));
        }
    }
}
