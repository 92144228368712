import {BrowserModule} from '@angular/platform-browser';
import {Injector, NgModule} from '@angular/core';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatCardModule} from '@angular/material/card';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatInputModule} from '@angular/material/input';
import {MatButtonModule} from '@angular/material/button';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatIconModule} from '@angular/material/icon';
import {MatMenuModule} from '@angular/material/menu';
import {MatTableModule} from '@angular/material/table';
import {MatTabsModule} from '@angular/material/tabs';
import {MatListModule} from '@angular/material/list';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatSelectModule} from '@angular/material/select';
import {MatNativeDateModule, MatOptionModule, MatRippleModule} from '@angular/material/core';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatRadioModule} from '@angular/material/radio';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatChipsModule} from '@angular/material/chips';
import {MatSliderModule} from '@angular/material/slider';
import {RouterModule} from '@angular/router';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatSortModule} from '@angular/material/sort';
import {MatPaginatorModule} from '@angular/material/paginator';
import {CommonModule as AngularCommonModule} from '@angular/common';
import {JwtModule} from '@auth0/angular-jwt';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {LazyLoadImageModule} from 'ng-lazyload-image';
import {ChartsModule} from 'ng2-charts';
import {BreadcrumbModule} from 'xng-breadcrumb';
import {jwtTokenGetter} from './utils/jwt-utils';
import {MatTableExporterModule} from 'mat-table-exporter';
import {LoginComponent} from './components/login/login.component';
import {CommonModule, ModuleConfig, ServiceInjectorService} from 'common';
import {environment} from '../environments/environment';
import {BaseComponent} from './components/base.component';
import {ToolbarComponent} from './components/toolbar/toolbar.component';
import {AccountComponent} from './components/account/account.component';
import {AccountsComponent} from './components/accounts/accounts.component';
import {AddSubscriptionComponent} from './components/add-subscription/add-subscription.component';
import {AdminReportFiltersComponent} from './components/admin-report-filters/admin-report-filters.component';
import {RecentAccountActivityReportComponent} from './components/recent-account-activity-report/recent-account-activity-report.component';
import {GenericAdminReportTableComponent} from './components/generic-admin-report-table/generic-admin-report-table.component';
import {AccountPurchasesInfoComponent} from './components/account-purchases-info/account-purchases-info.component';
import {AccountSubscriptionsComponent} from './components/account-subscriptions/account-subscriptions.component';
import {AccountInfoComponent} from './components/account-info/account-info.component';
import {SubscriptionsPanelComponent} from './components/subscriptions-panel/subscriptions-panel.component';
import {AdminHomeComponent} from './components/admin-home/admin-home.component';
import {BasicAuthInterceptor} from './utils/basic-auth-interceptor';
import {DevPlaygroundComponent} from './dev-playground/dev-playground.component';
import {FooterComponent} from './components/footer/footer.component';
import {RecentAccountPaymentsReportComponent} from './components/recent-account-payments-report/recent-account-payments-report.component';
import {FindSensorLatestUsageComponent} from './components/find-sensor-latest-usage/find-sensor-latest-usage.component';
import {FindSensorProductionLogsComponent} from './components/find-sensor-production-logs/find-sensor-production-logs.component';
import {MatDialogModule} from '@angular/material/dialog';
import {FindSensorProtocolAnalysisComponent} from './components/find-sensor-protocol-analysis/find-sensor-protocol-analysis.component';
import {DialogShowDetailedMessageComponent} from './components/dialogs/dialog-show-detailed-message';

@NgModule({
    declarations: [
        AppComponent,
        BaseComponent,
        LoginComponent,
        ToolbarComponent,
        AccountComponent,
        AccountsComponent,
        AddSubscriptionComponent,
        AdminReportFiltersComponent,
        RecentAccountActivityReportComponent,
        RecentAccountPaymentsReportComponent,
        GenericAdminReportTableComponent,
        AccountPurchasesInfoComponent,
        AccountSubscriptionsComponent,
        AccountInfoComponent,
        SubscriptionsPanelComponent,
        AdminHomeComponent,
        DevPlaygroundComponent,
        FindSensorLatestUsageComponent,
        FooterComponent,
        FindSensorProductionLogsComponent,
        FindSensorProtocolAnalysisComponent,
        DialogShowDetailedMessageComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        JwtModule.forRoot({
            config: {
                authScheme: 'Basic',
                tokenGetter: jwtTokenGetter
            }
        }),
        MatToolbarModule,
        MatCardModule,
        ReactiveFormsModule,
        MatInputModule,
        MatButtonModule,
        MatSnackBarModule,
        HttpClientModule,
        RouterModule,
        MatIconModule,
        MatMenuModule,
        MatTableModule,
        MatTabsModule,
        MatListModule,
        FormsModule,
        MatProgressBarModule,
        MatProgressSpinnerModule,
        MatSelectModule,
        MatOptionModule,
        MatTooltipModule,
        MatRadioModule,
        MatDatepickerModule,
        MatFormFieldModule,
        MatNativeDateModule,
        MatRippleModule,
        LazyLoadImageModule,
        ChartsModule,
        MatButtonToggleModule,
        MatSlideToggleModule,
        MatChipsModule,
        MatSliderModule,
        MatExpansionModule,
        MatSortModule,
        MatTableExporterModule,
        MatPaginatorModule,
        AngularCommonModule,
        BreadcrumbModule,
        CommonModule.forRoot(ModuleConfig.for(environment.apiUrl, environment.cdnUrl)),
        MatDialogModule,
    ],
    providers: [
        {provide: HTTP_INTERCEPTORS, useClass: BasicAuthInterceptor, multi: true},
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
    constructor(private injector: Injector) {
        ServiceInjectorService.injector = this.injector;
    }
}
