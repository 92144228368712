import {Component, OnDestroy} from "@angular/core";
import {AccountDto, CommonBaseComponent, ServiceInjectorService} from "common";
import {AdminNavigationService} from "../services/admin-navigation.service";
import {AuthenticationService} from "../authentication.service";
import {environment} from "../../environments/environment";

/**
 * Base component for all our component, where it makes sense. Simple ones might not need this.
 */
@Component({
    selector: 'app-base',
    template: '',
    styles: [''],
})
export class BaseComponent extends CommonBaseComponent implements OnDestroy {
    protected readonly authenticationService: AuthenticationService;
    protected readonly navigation: AdminNavigationService;

    constructor() {
        super();
        this.authenticationService = ServiceInjectorService.injector.get(AuthenticationService);
        this.navigation = ServiceInjectorService.injector.get(AdminNavigationService);
    }

    protected analyticsSwitchPage(title: string, url: string, path: string): void {
        this.analytics.switchPage(environment.googleAnalytics.measurementId, title, url, path);
    }

    /**
     * Returns the currently logged in account
     */
    public get userAccount(): AccountDto {
        return this.authenticationService.account;
    }
}
