<h1 class="flex-grow-1">Account Purchase History</h1>
<div *ngIf="purchases.length > 0; else noPurchases">
    <mat-accordion multi>
        <mat-expansion-panel *ngFor="let purchase of purchases" class="mb-2 pt-2 pb-2">
            <mat-expansion-panel-header class="align-items-center">
                <mat-panel-title>
                    <div class="align-self-center">{{purchase.product}}</div>
                </mat-panel-title>
                <mat-panel-description class="flex-column align-items-center">
                    <small class="me-1">subscribed On</small>
                    <div>
                        {{purchase.startedOn | date: 'longDate'}}
                    </div>
                </mat-panel-description>
                <mat-panel-description class="flex-column align-items-center">
                    <small class="me-1">Billing Period</small>
                    <div>
                        {{purchase.billingPeriod}}
                    </div>
                </mat-panel-description>
                <mat-panel-description class="flex-column align-items-center">
                    <small class="me-1">Payments</small>
                    <div>
                        {{purchase.payments.length}}
                    </div>
                </mat-panel-description>
                <mat-panel-description class="flex-column align-items-center">
                    <small class="me-1">Status</small>
                    <div>
                        {{purchase.status}}
                    </div>
                </mat-panel-description>
            </mat-expansion-panel-header>
            <div mat-subheader>Subscription Payments</div>
            <mat-list>
                <mat-list-item *ngFor="let paymentInfo of purchase.payments" class="mb-2 row">
                    <mat-icon mat-list-icon class="col-4 pe-4">
                        payments
                    </mat-icon>
                    <mat-panel-description class="col-2 flex-column">
                        <small class="me-1">Date Paid</small>
                        <div>
                            {{paymentInfo.datePaid | date: 'longDate'}}
                        </div>
                    </mat-panel-description>
                    <mat-panel-description class="col-2 flex-column">
                        <small class="me-1">Payment Method</small>
                        <div mat-line>
                            {{paymentInfo.paymentMethod}}
                        </div>
                    </mat-panel-description>
                    <mat-panel-description class="col-2 flex-column">
                        <small class="me-1">Payment Amount</small>
                        <div mat-line>
                            {{paymentInfo.total + ' ' + purchase.currency}}
                        </div>
                    </mat-panel-description>
                    <mat-panel-description class="col-2 flex-column">
                        <small class="me-1">Discount</small>
                        <div mat-line>
                            {{paymentInfo.discount_total}}%
                        </div>
                    </mat-panel-description>
                    <mat-panel-description class="col-4 flex-column" *ngIf="hasTransactionId(paymentInfo);else emptyTransactionId">
                        <small class="me-1">Transaction Id</small>
                        <div mat-line>
                            {{paymentInfo.transactionId}}
                        </div>
                    </mat-panel-description>
                    <mat-divider></mat-divider>
                </mat-list-item>
            </mat-list>
        </mat-expansion-panel>
    </mat-accordion>
</div>

<ng-template #emptyTransactionId>
    <div class="col-4 flex-column"></div>
</ng-template>
<ng-template #noPurchases>
    <div class="ms-1">None</div>
</ng-template>
