import {BaseDto} from "common";

export class SubscriptionPaymentsDto extends BaseDto {
    datePaid: number
    discount_total: string
    paymentMethod: string
    status: string
    total: string
    transactionId: string

    public deserialize(input: any): this {
        Object.assign(this, input);
        return this;
    }
}
