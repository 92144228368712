import {ProtocolFindProductionLogsDataWrapper} from '../wrappers/protocolFindProductionLogsDataWrapper';

export class ProtocolFindProductionLogsViewModel {
    position: number;
    product: string;
    application: string;
    timestampDate: number;
    message: string;
    userCode: string;
    deviceModel: string;
    productVersion: string;
    applicationVersion: string;

    public static from(dataWrapper: ProtocolFindProductionLogsDataWrapper[]): ProtocolFindProductionLogsViewModel[] {
        const data = [];
        dataWrapper.forEach ((dataRow, i) => {
            const convertedData = new ProtocolFindProductionLogsViewModel();
            convertedData.position = i + 1;
            convertedData.product = dataRow.product;
            convertedData.application = dataRow.application;
            convertedData.timestampDate = dataRow.timestampDate;
            convertedData.message = dataRow.message;
            convertedData.userCode = dataRow.userCode;
            convertedData.deviceModel = dataRow.deviceModel;
            convertedData.productVersion = dataRow.productVersion;
            convertedData.applicationVersion = dataRow.applicationVersion;

            data.push(convertedData);
        });
        return data;
    }
}
