import {AdminReportStatusDto} from "./adminReportStatus.dto";
import {RecentAccountActivityDto} from "./recentAccountActivity.dto";
import {Dto} from "common";

/**
 * reflects RecentAccountActivityReportDto dto on server
 */
export class RecentAccountActivityReportDto extends Dto {
    reportStatus: AdminReportStatusDto;
    rows: RecentAccountActivityDto[];

    public deserialize(input: any): this {
        Object.assign(this, input);

        this.rows = this.deserializeArray(input.rows, RecentAccountActivityDto);

        return this;
    }
}
