<div *ngIf="subscriptions.length > 0; else noSubscriptions">
    <mat-accordion multi>
        <mat-expansion-panel *ngFor="let subscription of subscriptions" class="subscription">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <div class="title flex-grow-1">{{subscription.name}}</div>
                </mat-panel-title>
                <mat-panel-description>
                    <small class="me-1">Expires</small>
                    <div *ngIf="subscription.canExpire; else neverExpires">
                    {{subscription.expirationDate | date: 'longDate'}}
                        {{subscription.expirationDate | date: 'shortTime'}}
                    </div>
                    <ng-template #neverExpires><span>Never</span></ng-template>
                </mat-panel-description>
            </mat-expansion-panel-header>
            <div>
                <mat-list>
                    <mat-list-item title="Subscription Status">
                        <mat-icon mat-list-icon
                                  [ngClass]="{'icon-active' : subscription.enabled && !subscription.isExpired, 'icon-inactive' : !subscription.enabled, 'icon-expired' : subscription.isExpired && subscription.enabled}">
                            power_settings_new
                        </mat-icon>
                        <div mat-line class="detail">
                            <small>Status</small>
                            <div>{{formatStatus(subscription)}}</div>
                        </div>
                    </mat-list-item>
                    <mat-list-item title="Started">
                        <mat-icon mat-list-icon>date_range_icon</mat-icon>
                        <div mat-line class="detail">
                            <small>Subscription Started</small>
                            <div>{{subscription.subscribedOn | date: 'longDate'}} {{subscription.subscribedOn | date: 'shortTime'}}</div>
                        </div>
                    </mat-list-item>
                    <mat-list-item title="Expires">
                        <mat-icon mat-list-icon>timelapse</mat-icon>
                        <div mat-line class="detail">
                            <small>Expiration</small>
                            <div *ngIf="subscription.canExpire; else neverExpiresDetail">
                                {{subscription.expirationDate | date: 'longDate'}}
                                {{subscription.expirationDate | date: 'shortTime'}}
                            </div>
                            <ng-template #neverExpiresDetail><div>Never</div></ng-template>
                        </div>
                    </mat-list-item>
                    <mat-list-item title="Duration" *ngIf="subscription.canExpire">
                        <mat-icon mat-list-icon>timer</mat-icon>
                        <div mat-line class="detail">
                            <span><small>Duration:</small></span>
                            <span>{{subscription.duration}} days</span>
                        </div>
                    </mat-list-item>
                </mat-list>
                <button (click)="onDeactivate.emit(subscription)" color="warn" class="me-2" *ngIf="subscription.enabled && !subscription.isExpired" mat-raised-button>
                    Deactivate
                </button>
            </div>
        </mat-expansion-panel>
    </mat-accordion>
</div>
<ng-template #noSubscriptions>
    <div class="ms-1">None</div>
</ng-template>
